import React, { Component } from 'react';
import View from './View.js';
import { connect } from 'react-redux';
import './index.scss';
import queryString from 'query-string';
import { GET, POST } from '../../../Commons/Utils/fetch';
import * as userActions from '../../../Commons/Store/Actions/user';
import * as gnbActions from '../../../Commons/Store/Actions/gnb';
import { COUNTRY, KEY } from '../../../Commons/Utils/constant';
import { injectIntl } from 'react-intl';
import { checkIsMobile } from '../../../Commons/Utils/deviceHelper';

class Container extends Component {
  constructor(props) {
    super(props);

    this.PLATFORM = {
      AUTH: 'auth',
      KAKAO: 'kakao',
      NAVER: 'naver',
      FB: 'facebook',
      GOOGLE: 'google',
    };

    this.isMobile = checkIsMobile();

    this.state = {
      toggleLoginFrom: false,
      email: '',
      password: '',
      selectedCountry: COUNTRY.TYPE.KOREAN,
    };
  }

  get isLoginPage() {
    return this.props.location.pathname.startsWith('/login');
  }

  getUser = async (token) => {
    try {
      const { user } = await GET({
        url: `/user/login`,
        header: {
          EJE_API_KEY: token,
        },
      });

      this.props.login(user);
    } catch (error) {
      console.error(error);
    }
  };

  getGnbList = async () => {
    try {
      const { data } = await GET({
        url: '/auth/system/gnbs',
      });

      this.props.setGnb(data.rows);
    } catch (error) {
      console.error(error);
    }
  };

  getLectureLikeList = async (token) => {
    try {
      const { lectureIds } = await GET({
        url: '/classes/me/like-list',
        header: {
          EJE_API_KEY: token,
        },
      });

      this.props.setLectureLikeList(lectureIds);
    } catch (e) {
      console.error(e);
    }
  };

  async componentDidMount() {
    const token = localStorage.getItem('eje_token') || null;

    // Local Storage에 eje_token 값이 있다면 토큰에 해당하는 유저 로그인되도록
    if (token) {
      const userInfo = await this.getUser(token);
      if (!userInfo) {
        // userInfo 가져오지 못 할 경우 eje_token 초기화 후 로그인 페이지로 이동
        localStorage.removeItem('eje_token');
        this.props.history.push('/login');
      } else {
        this.props.history.push('/');
      }
    }

    this.initCountryType();
    this.initNaverLogin();
    this.routeMatchParams();
  }

  componentWillUnmount() {
    // 로그인 페이지 벗어날 때, redirect url 초기화
    localStorage.removeItem('prev_class_id');
    sessionStorage.removeItem('login_redirect');
  }

  /** Init methods */
  initCountryType = () => {
    let eje_country_type = localStorage.getItem('eje_country_type');
    if (!eje_country_type) {
      localStorage.setItem('eje_country_type', COUNTRY.TYPE.KOREAN);
    }
    this.setState({
      selectedCountry: eje_country_type ? eje_country_type : COUNTRY.TYPE.KOREAN,
    });
  };

  initNaverLogin = () => {
    const naverLogin = new window.naver.LoginWithNaverId({
      clientId: KEY.NAVER.CLIENT_ID,
      callbackUrl: `${process.env.REACT_APP_HOST}/login/naver`,
      isPopup: false,
      loginButton: { color: 'green', type: 3, height: '40' }, //버튼의 스타일, 타입, 크기를 지정
    });

    naverLogin.init();
  };

  routeMatchParams = () => {
    const routeParams = this.props.match.params;

    // TODO: Login Page 접근 방식 => 로그인 => PLATFORM
    if (routeParams?.platform) {
      // error page redirect
      const { AUTH, KAKAO, NAVER, FB, GOOGLE } = this.PLATFORM;
      let query;

      switch (routeParams.platform) {
        case AUTH:
          return;
        case KAKAO:
        case FB:
        case GOOGLE:
          query = queryString.parse(window.location.search);
          break;
        case NAVER:
          query = queryString.parse(window.location.hash);
          break;

        default:
          // error page redirect
          return;
      }
      if (!query) {
        // error page redirect
        return;
      }

      this.callbackPlatform(routeParams.platform, query);
    }
  };

  callbackPlatform = async (platform, query) => {
    const { KAKAO, NAVER, FB, GOOGLE } = this.PLATFORM;
    try {
      const { code, access_token, tokenId } = query;
      let body = {};
      switch (platform) {
        case KAKAO:
          if (!code) {
            // kakao login error
          }
          body['code'] = code;

          break;
        case NAVER:
          if (!access_token) {
            // naver login error
          }
          body['access_token'] = access_token;
          break;

        case GOOGLE:
          body['tokenId'] = tokenId;
          break;
        case FB:
          body['code'] = code;
          body['access_token'] = access_token;
          break;

        default:
          // error page redirect
          break;
      }

      // 로그인 시 공지사항 조회 내역 전송
      const readNoticeHistories = JSON.parse(localStorage.getItem('readNoticeHistories'));

      let loginResponse = await POST({
        header: {
          EJE_API_KEY: 'auth',
        },
        url: `/auth/login/${platform}`,
        body: {
          ...body,
          read_notice_histories: readNoticeHistories,
        },
      });

      if (loginResponse.success) {
        // 토큰 회원 조회시 필요한 토큰을 Local Storage에 저장
        localStorage.setItem('eje_token', loginResponse.token);
        this.props.login(loginResponse.user);
        this.getGnbList();
        this.getLectureLikeList(loginResponse.token);

        const prevClassId = localStorage.getItem('prev_class_id');
        const redirectURL = sessionStorage.getItem('login_redirect');
        localStorage.removeItem('prev_class_id');
        sessionStorage.removeItem('login_redirect');

        if (prevClassId) {
          const { search } = window.location;
          this.props.history.replace(`/lecture/${prevClassId}${search}`);
        } else if (redirectURL) {
          this.props.history.replace(redirectURL);
        } else {
          this.props.history.push('/');
        }

        // 로그인 성공 시 공지사항 조회 내역 제거
        localStorage.removeItem('readNoticeHistories');
      } else {
        if (!loginResponse.account) throw Error('loginResponse.account is not found');

        let redirectURL;

        let redirectQuery = {
          ...loginResponse.account,
          loginType: platform,
        };

        const { search } = window.location;
        switch (platform) {
          case KAKAO:
          case NAVER:
          case GOOGLE:
          case FB:
            redirectURL = `${!!search ? '&' : '?'}${queryString.stringify(redirectQuery)}`;
            this.props.history.replace('/signupForm' + search + redirectURL);
            break;
          // case GOOGLE:
          // case FB:
          //   // 외국인이면, 외국인 전용으로 가게 만들어야.
          //   break;

          default:
            throw Error('unknown platform');
        }
      }
    } catch (error) {
      console.error(error);
      await swal({
        icon: 'error',
        text: this.props.intl.formatMessage({
          id: 'SOCIAL_LOGIN_ERROR',
        }),
      });

      this.props.history.push('/');
    }
  };

  handleSMSLogin = (type, payload) => {
    const { KAKAO, NAVER, FB } = this.PLATFORM;
    switch (type) {
      case KAKAO:
        window.Kakao.Auth.authorize({
          redirectUri: window.location.origin.replace('www.', '') + '/login/kakao',
        });

        break;
      case NAVER:
        const naverElement = document.getElementById('naverIdLogin');
        const naverBtn = naverElement && naverElement.firstChild ? naverElement.firstChild : undefined;
        if (naverBtn) naverBtn.click();

        break;
      case FB:
        window.FB.login((response) => {
          if (response.status === 'connected') {
            this.onResponseSMS(FB, response.authResponse);
          } else {
            swal({
              icon: 'error',
              text: this.props.intl.formatMessage({
                id: 'SOCIAL_LOGIN_ERROR',
              }),
            });
          }
        });

        break;
      default:
        break;
    }
  };

  onResponseSMS = (type, response) => {
    const { GOOGLE, FB } = this.PLATFORM;
    let callbackPlatformParam = {};
    switch (type) {
      case GOOGLE:
        const { tokenId } = response;
        if (tokenId) {
          callbackPlatformParam['tokenId'] = tokenId;
          this.callbackPlatform(type, callbackPlatformParam);
        }
        break;
      case FB:
        // if (this.isMobile) return;
        const { accessToken } = response;
        if (accessToken) {
          callbackPlatformParam['access_token'] = accessToken;
          this.callbackPlatform(type, callbackPlatformParam);
        }
        break;

      default:
        break;
    }
  };

  onChangeEmail = (e) => {
    const value = e.target.value;
    this.setState({
      email: value,
    });
  };

  onChangePassword = (e) => {
    const value = e.target.value;
    this.setState({
      password: value,
    });
  };

  onClickToggleLoginForm = () => {
    this.setState((prevState) => ({
      toggleLoginFrom: !prevState.toggleLoginFrom,
    }));
  };

  onSubmit = async (e) => {
    e.preventDefault();
    await this.loginWithEmail();
  };

  loginWithEmail = async () => {
    const { email, password } = this.state;

    let loginResponse;
    try {
      const readNoticeHistories = JSON.parse(localStorage.getItem('readNoticeHistories'));

      loginResponse = await POST({
        header: {
          EJE_API_KEY: 'auth',
        },
        url: `/auth/emaillogin`,
        body: {
          email,
          password,
          read_notice_histories: readNoticeHistories,
        },
      });
    } catch (error) {
      console.error(error);
      swal({
        icon: 'error',
        text: this.props.intl.formatMessage({
          id: 'ERROR_NETWORK',
        }),
      });

      return;
    }

    if (loginResponse) {
      if (loginResponse.success) {
        localStorage.removeItem('readNoticeHistories');

        if (loginResponse.isFirstLogin) {
          let redirectQuery = {
            email: loginResponse.user.email,
            token: loginResponse.token,
            nickname: loginResponse.user.nickname,
          };
          this.props.history.push(`/prev/signupForm?${queryString.stringify(redirectQuery)}`);
        } else {
          localStorage.setItem('eje_token', loginResponse.token);
          this.props.login(loginResponse.user);
          this.getGnbList();
          this.getLectureLikeList(loginResponse.token);

          const prevClassId = localStorage.getItem('prev_class_id');
          const redirectURL = sessionStorage.getItem('login_redirect');
          localStorage.removeItem('prev_class_id');
          sessionStorage.removeItem('login_redirect');

          if (prevClassId) {
            const { search } = window.location;
            this.props.history.replace(`/lecture/${prevClassId}${search}`);
          } else if (redirectURL) {
            this.props.history.replace(redirectURL);
          } else {
            this.props.history.replace('/');
            window.location.reload();
          }
        }
      } else {
        swal({
          icon: 'error',
          text: this.props.intl.formatMessage({
            id: 'LOGIN_VALIDATE_ERROR',
          }),
        });
      }
    } else {
      swal({
        icon: 'error',
        text: this.props.intl.formatMessage({
          id: 'ERROR_NETWORK',
        }),
      });
    }
  };

  handleCountry = (value) => {
    localStorage.setItem('eje_country_type', value);
    this.setState({
      selectedCountry: value,
    });
  };

  render() {
    const {
      handleSMSLogin,
      onResponseSMS,
      onChangeEmail,
      onChangePassword,
      onClickToggleLoginForm,
      onSubmit,
      handleCountry,
      isLoginPage,
    } = this;
    const { toggleLoginFrom, email, password, selectedCountry } = this.state;
    return (
      <View
        handleSMSLogin={handleSMSLogin}
        onChangeEmail={onChangeEmail}
        onChangePassword={onChangePassword}
        onClickToggleLoginForm={onClickToggleLoginForm}
        onSubmit={onSubmit}
        toggleLoginFrom={toggleLoginFrom}
        email={email}
        password={password}
        onResponseSMS={onResponseSMS}
        selectedCountry={selectedCountry}
        handleCountry={handleCountry}
        isLoginPage={isLoginPage}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.user.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  login: (payload) => dispatch(userActions.login(payload)),
  setGnb: (list) => dispatch(gnbActions.setGnb(list)),
  setLectureLikeList: (list) => dispatch(userActions.setLectureLikeList(list)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Container));
