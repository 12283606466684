export const URL = {
  API_SERVER: process.env.REACT_APP_API_SERVER,
  ADMIN: process.env.REACT_APP_ADMIN,
  HOST: process.env.REACT_APP_HOST,
};

export const KEY = {
  NAVER: {
    CLIENT_ID: process.env.REACT_APP_NAVER_CLIENT_ID,
  },
};

export const TRANSACTION = {
  TYPE: {
    METHOD: {
      CARD: 'card',
      KAKAOPAY: 'kakaopay',
      VBANK: 'vbank',
      TOSSPAY: 'tosspay',
    },
    PG: {
      INICIS: 'html5_inicis',
      paypal: 'paypal',
    },
    STATUS: {
      PENDING: 'pending', // 초기값
      PAID: 'paid',
      READY: 'ready', // 가상계좌가 발급되었을 경우
      FAILED: 'failed', //
      PARTIAL_CANCELLED: 'partial_cancelled', // 부분 환불
      CANCELLED: 'cancelled', // 환불
    },
  },
};

export const COUNTRY = {
  TYPE: {
    KOREAN: 'korean',
    FOREIGNER: 'foreigner',
  },
};

export const LECTURE = {
  TYPE: {
    ONLINE: {
      ONLINE: 'online',
      ROUNDONLINE: 'roundOnline',
      FOREIGNER: 'foreigner',
    },
    OFFLLNE: {
      OFFLINE: 'offline',
      CONSULTING: 'consulting',
    },
  },
};

export const DEFAULT_SEO_DESC = 'SM HYBE YG JYP CJENM 등 국내 엔터테인먼트 미디어 콘텐츠 취업 정보는 엔터잡에듀에서!';
