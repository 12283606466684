import dayjs from 'dayjs';
import queryString from 'query-string';
import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { TRANSACTION } from '../../Commons/Utils/constant';
import { GET, POST } from '../../Commons/Utils/fetch';
import updateState from '../../Commons/Utils/UpdateState';
import View from './View';
import Swal from 'sweetalert2';

const { PAID, READY, FAILED } = TRANSACTION.TYPE.STATUS;

class Container extends PureComponent {
  PAYMENT_IMPLEMENTS = [
    {
      key: 'PAYMENT_IMPLEMENTS_CREDIT_OR_CHECK_CARD',
      pg: 'html5_inicis',
      pay_method: 'card',
    },
    {
      key: 'PAYMENT_IMPLEMENTS_V_BANK',
      pg: 'html5_inicis',
      pay_method: 'vbank',
    },
    {
      key: 'PAYMENT_IMPLEMENTS_KAKAOPAY',
      pg: 'html5_inicis',
      pay_method: 'kakaopay',
    },
    {
      key: 'PAYMENT_IMPLEMENTS_TOSS',
      pg: 'html5_inicis',
      pay_method: 'tosspay',
    },
  ];
  state = {
    isLoading: true,
    isValid: false,
    lecture: null,
    agreementTerms: false,
    isValidation: false,
  };

  constructor(props) {
    super(props);

    // 오프라인 강의 경우, 기수 ID
    this.class_offline_schedule_id = queryString.parse(window.location.search).class_offline_schedule_id || null;

    // 온라인(기수제) 강의 경우
    this.class_online_schedule_id = queryString.parse(window.location.search).class_online_schedule_id || null;
  }

  /** Life Cycle */
  async componentDidMount() {
    await this.getThirdPartyQuestionFieldList();
    await this.getLecture();
  }

  componentDidUpdate() {}

  /** Fetchs */
  getLecture = async () => {
    const { id } = this.props.match.params;
    this.setState({ isLoading: true });

    const { classModel, isOnOtherSchedule } = await GET({
      url: `/payment/class/${id}`,
      header: {
        EJE_API_KEY: localStorage.getItem('eje_token'),
      },
      params: {
        scheduleId:
          this.class_offline_schedule_id || this.class_consulting_schedule_id || this.class_online_schedule_id,
      },
    });

    if (!classModel) {
      await swal({
        icon: 'warning',
        text: this.props.intl.formatMessage({
          id: 'PAYMENT_ALREADY_BEEN_PAID',
        }),
      });
      this.props.history.replace('/my/lectures');
      return;
    }

    if (!classModel.is_selling) {
      await swal({
        icon: 'warning',
        text: this.props.intl.formatMessage({
          id: 'DETAIL_LECTURE_NOT_SELLiNG',
        }),
      });
      this.props.history.push('/');
      return;
    }

    if (isOnOtherSchedule) {
      const confirmRes = await Swal.fire({
        title: '다른 옵션으로 결제한 강의입니다.',
        text: '계속해서 결제를 진행할까요?',
        confirmButtonText: '확인',
        confirmButtonColor: '#F24462',
        showCancelButton: true,
        cancelButtonText: '취소',
        cancelButtonColor: '#A2A2A2',
        icon: 'info',
        footer:
          '<a href="/my/lectures" style="color: #F24462; text-decoration: none; font-weight: bold;">내 강의실로 이동</a>',
      });

      if (!confirmRes.isConfirmed) {
        this.props.history.goBack();
      }
    }

    await updateState(this, {
      lecture: classModel,
      isLoading: false,
    });
  };

  getThirdPartyQuestionFieldList = async () => {
    const fields = await GET({
      url: `/class/third-party/question-fields`,
      header: {
        EJE_API_KEY: localStorage.getItem('eje_token'),
      },
    });

    console.log('fields: ', fields);

    await updateState(this, {
      thirdPartyQuestionFieldList: fields,
    });
  };

  onClickToggleAgreementTerms = () => {
    this.setState((prevState) => ({
      ...prevState,
      agreementTerms: !prevState.agreementTerms,
    }));
  };

  isValidEmail = (email) =>
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email,
    );

  isValidPhone = (phone) => /^010[-\s\.]?[0-9]{4}[-\s\.]?[0-9]{4}$/.test(phone);

  validInputValues = (inputValues) => {
    const { agreement, user_phone, user_email } = inputValues;
    /**
     * user_type_name은
     * user_type이 "기타"가 아니면 user_type과 동일하고
     * user_type이 "기타"일 경우,
     *  user_type_name이 빈 값이면 user_type과 동일하게
     *  user_type_name이 빈 값이 아니면 해당 값을 사용한다.
     */
    const { lecture, thirdPartyQuestionFieldList } = this.state;

    const requiredFields = lecture ? lecture.ThirdPartyClassQuestions.map((question) => question.question_field) : [];

    const missingFields = requiredFields.filter(
      (field) => !inputValues[field] || inputValues[field] === '' || inputValues[field] === 'etc',
    );

    if (missingFields.length > 0) {
      const missingFieldNames = missingFields.map(
        (field) => Object.values(thirdPartyQuestionFieldList).find((item) => item.field === field).label,
      );

      swal({
        icon: 'error',
        text: `다음 항목을 작성해주세요: ${missingFieldNames.join(', ')}`,
        timer: 3000,
        buttons: false,
      });

      return false;
    } else if (!this.isValidEmail(user_email)) {
      swal({
        icon: 'error',
        text: '이메일을 확인해주세요',
        timer: 3000,
        buttons: false,
      });
      return false;
    } else if (!this.isValidPhone(user_phone)) {
      swal({
        icon: 'error',
        text: '연락처를 확인해주세요',
        timer: 3000,
        buttons: false,
      });
      return false;
    } else if (!agreement) {
      swal({
        icon: 'error',
        text: '교육정보 확인 및 개인정보 제공에 동의하셔야 합니다',
        timer: 3000,
        buttons: false,
      });
      return false;
    }

    return true;
  };

  onClickPayment = async (inputValues) => {
    const { lecture, thirdPartyQuestionFieldList } = this.state;
    const {
      agreement,
      user_phone,
      user_type,
      user_birth,
      lecture_title,
      user_name,
      user_email,
      user_gender,
      user_education,
      user_school_name,
      user_school_department,
      user_apply_reason,
    } = inputValues;

    const user_type_name = user_type !== '기타' || !inputValues.user_type_name ? user_type : inputValues.user_type_name;
    /** validation check */
    const isValidation = this.validInputValues(inputValues);
    this.setState({ isValidation });
    if (isValidation) {
      const body = {
        lecture_title,
        user_name,
        user_phone: +this.globalFormatPhoneNumber(user_phone),
        user_email,
        user_birth: dayjs(user_birth).format('YYYY-MM-DD'),
        user_type,
        user_type_name,
        user_gender,
        user_education,
        user_school_name,
        user_school_department,
        user_apply_reason,
        consent_receive: agreement,
        consent_3rd: agreement,
      };

      const questionFields = lecture ? lecture.ThirdPartyClassQuestions.map((question) => question.question_field) : [];
      const questionFieldList = Object.values(thirdPartyQuestionFieldList);
      const confirmFieldValue = [
        {
          field: 'user_name',
          value: body.user_name,
          title: questionFieldList.find((item) => item.field === 'user_name').label,
        },
        {
          field: 'user_phone',
          value: user_phone,
          title: questionFieldList.find((item) => item.field === 'user_phone').label,
        },
        {
          field: 'user_email',
          value: body.user_email,
          title: questionFieldList.find((item) => item.field === 'user_email').label,
        },
        {
          field: 'user_birth',
          value: body.user_birth,
          title: questionFieldList.find((item) => item.field === 'user_birth').label,
        },
        {
          field: 'user_gender',
          value: body.user_gender === 'M' ? '남자' : '여자',
          title: questionFieldList.find((item) => item.field === 'user_gender').label,
        },
        {
          field: 'user_education',
          value: body.user_education,
          title: questionFieldList.find((item) => item.field === 'user_education').label,
        },
        {
          field: 'user_school_name',
          value: body.user_school_name,
          title: questionFieldList.find((item) => item.field === 'user_school_name').label,
        },
        {
          field: 'user_school_department',
          value: body.user_school_department,
          title: questionFieldList.find((item) => item.field === 'user_school_department').label,
        },
        {
          field: 'user_apply_reason',
          value: body.user_apply_reason,
          title: questionFieldList.find((item) => item.field === 'user_apply_reason').label,
        },
      ].filter((item) => questionFields.includes(item.field));

      const confirmHeadText = '강의명: ' + lecture_title + '\n';
      const confirmText = confirmFieldValue.map((item) => `${item.title}: ${item.value}\n`).join('');
      const confirmTailText = `\n\n ${lecture.registration_text}`;

      const confirm = await swal({
        title: '신청정보 확인',
        text: confirmHeadText + confirmText + confirmTailText,
        closeOnClickOutside: false,
        buttons: {
          cancel: {
            text: '수정',
            value: null,
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: '확인',
            value: true,
            visible: true,
            closeModal: true,
          },
        },
      });

      if (confirm) {
        // postTransaction
        await this.applyForGovFundedCourses(body);
      } else {
        // end process
      }
    }
  };

  applyForGovFundedCourses = (body) => {
    const showFailModal = () =>
      swal({
        icon: 'error',
        text: '수강신청에 실패했습니다.',
      });
    try {
      const { lecture } = this.state;
      this.postGovFundedCoursesTransaction({
        ...body,
        class_id: lecture.id,
        class_discount_amount: 0, // TODO: 추후 유료 강의일 경우 할인금액을 받아야 함
        paid_amount: 0, // TODO: 추후 유료 강의일 경우 결제금액을 받아야 함
        class_offline_schedule_id: this.class_offline_schedule_id,
      }).then((res) => {
        if (res.success) {
          swal({
            icon: 'success',
            text: '수강신청이 완료되었습니다.',
            timer: 2000,
            buttons: false,
          }).then(() => {
            this.props.history.push(`/payment/complete?status=${res.status}&merchant_uid=${res.merchant_uid}&host=gov`);
          });
        } else {
          showFailModal();
        }
      });
    } catch (e) {
      showFailModal();
    }
  };

  postGovFundedCoursesTransaction = async (body) => {
    const response = await POST({
      url: '/payment/thirdparty/registration',
      header: {
        EJE_API_KEY: localStorage.getItem('eje_token'),
      },
      body,
    });

    if (response.success) {
      this.setState({
        isValidation: false,
        isPayment: true,
        payment: response.data,
      });
      return response;
    } else {
      swal({
        icon: 'error',
        text: response.message || '관리자에게 문의해주세요!',
      });
    }
  };

  /** Helper Methods */
  formatPhoneNumber = (input) => {
    const cleanInput = input.replaceAll(/\D/g, '');
    let result = '';
    const length = cleanInput.length;
    result = cleanInput.replace(/^82/, '0');
    let phoneNumber = '';
    if (result.length <= 3) phoneNumber = result;
    else if (result.length <= 7) phoneNumber = `${result.slice(0, 3)}-${result.slice(3)}`;
    else phoneNumber = `${result.slice(0, 3)}-${result.slice(3, 7)}` + `-${result.slice(7, 11)}`;
    result = phoneNumber;
    return result;
  };

  globalFormatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return undefined;
    const cleanPhoneNumber = phoneNumber.replaceAll(/-/g, '');
    let result = '';
    result = cleanPhoneNumber.replace(/^0/, '82');
    return result;
  };

  render() {
    const { onClickToggleAgreementTerms, onClickPayment, formatPhoneNumber } = this;
    const { isLoading, lecture, agreementTerms, isValidation, thirdPartyQuestionFieldList } = this.state;
    return (
      <View
        isLoading={isLoading}
        isValidation={isValidation}
        lecture={lecture}
        onClickToggleAgreementTerms={onClickToggleAgreementTerms}
        agreementTerms={agreementTerms}
        onClickPayment={onClickPayment}
        formatPhoneNumber={formatPhoneNumber}
        thirdPartyQuestionFieldList={thirdPartyQuestionFieldList}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.userInfo,
  isLoggedIn: state.user.isLoggedIn,
});

export default connect(mapStateToProps)(injectIntl(Container));
