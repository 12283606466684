import Meta from '../../../Commons/Components/Meta';
import Main from '../../../Commons/Layouts/Main';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { GET, PATCH } from '../../../Commons/Utils/fetch';
import dayjs from 'dayjs';
import queryString from 'query-string';
import Swal from 'sweetalert2';

import '../../LectureDetail/quill.scss';
import { AttachFile, FileCopy, Image, Movie, MusicNote, PictureAsPdf } from '@mui/icons-material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { postMetaInfo } from '../../../Commons/Utils/metaInfo';
import LoginItem from '../../../Commons/Components/LoginItem';
import { Button } from '@mui/material';

const Notice = () => {
  const history = useHistory();
  const location = useLocation();
  const boardCode = location.pathname.split('/')[1];
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const { id } = useParams();
  const { page = 1 } = queryString.parse(location.search);
  const ejeToken = localStorage.getItem('eje_token');

  const [isLoaded, setIsLoaded] = useState(false);
  const [listCount, setListCount] = useState(0);
  const [list, setList] = useState([]);
  const [detail, setDetail] = useState(null);
  const [nextPost, setNextPost] = useState(null);
  const [prevPost, setPrevPost] = useState(null);
  const [currentPage, setCurrentPage] = useState(+page);

  const fetchDetail = async (id) => {
    return await GET({
      url: `/auth/posts/${id}`,
      params: {
        group: 'service',
        board: boardCode,
      },
    });
  };

  const fetchData = useCallback(async () => {
    // 상세 데이터 획득
    try {
      const {
        success,
        data: { nextPost, post, prevPost },
      } = await fetchDetail(id);
      if (success) {
        setDetail(post);
        setNextPost(nextPost);
        setPrevPost(prevPost);
        setIsLoaded(true);

        if (!isLoggedIn) {
          const readNoticeIds = JSON.parse(localStorage.getItem('readNoticeHistories')) || [];
          if (readNoticeIds.includes(id)) return;
          if (readNoticeIds.length > 0) {
            const newReadNoticeIds = [...readNoticeIds, id];
            localStorage.setItem('readNoticeHistories', JSON.stringify(newReadNoticeIds));
          } else {
            localStorage.setItem('readNoticeHistories', JSON.stringify([id]));
          }
        }
      }
    } catch (err) {
      if (err.status_code === 404 || err.status_code === 403) {
        Swal.fire({
          icon: 'warning',
          html: `<h3>권한이 없거나 존재하지 않는 게시글입니다.</h3><br/><br/><p>${
            isLoggedIn ? '궁금하신 사항은 우측 하단 채널톡으로 문의해주세요.' : '로그인 후에 다시 시도해주세요.'
          }</p>`,
          showConfirmButton: true,
          confirmButtonText: `${isLoggedIn ? '돌아가기' : '로그인'}`,
          preConfirm: () => {
            isLoggedIn ? history.replace(`/${boardCode}`) : history.replace('/login');
          },
        });
      }
    }
  }, [id, page]);

  useEffect(() => {
    fetchData();
  }, [id, page]);

  const fileSizeText = useCallback((size) => {
    if (size < 1024) {
      return `${size} B`;
    } else if (size < 1024 * 1024) {
      return `${(size / 1024).toFixed(2)} KB`;
    } else if (size < 1024 * 1024 * 1024) {
      return `${(size / 1024 / 1024).toFixed(2)} MB`;
    } else {
      return `${(size / 1024 / 1024 / 1024).toFixed(2)} GB`;
    }
  }, []);

  const renderFileIcon = useCallback(({ type, extension }) => {
    switch (type) {
      case 'image':
        return <Image sx={{ fontSize: '1rem' }} />;
      case 'video':
        return <Movie sx={{ fontSize: '1rem' }} />;
      case 'audio':
        return <MusicNote sx={{ fontSize: '1rem' }} />;
      case 'document':
        if (extension === 'pdf') return <PictureAsPdf sx={{ fontSize: '1rem' }} />;
        else return <FileCopy sx={{ fontSize: '1rem' }} />;
      default:
        return <AttachFile sx={{ fontSize: '1rem' }} />;
    }
  }, []);

  const popupError = () =>
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      html: '파일을 다운로드 할 수 없습니다.<br />관리자에게 문의해주세요.',
      timer: 4000,
      showConfirmButton: false,
    });

  return (
    <Main>
      {detail ? <Meta metaInfo={postMetaInfo(detail.title, detail.content, location.pathname)} /> : <Meta />}

      <style jsx="true">{`
        #notice-detail-container {
          width: 100%;
          max-width: 1200px;
          margin: 0 auto;
          box-sizing: border-box;
          padding: 2.625rem 0 8rem;
          display: flex;
          flex-direction: column;
          row-gap: 2.5rem;

          a {
            color: #306fd8;
            text-decoration: underline;

            &:hover {
              color: #212eac;
            }

            &:visited {
              color: purple;
            }
          }
        }

        #notice-detail-container h2 {
          font-size: 1.5rem;
          letter-spacing: -0.4px;
          color: #000000;
          font-weight: bold;
        }

        #notice-detail-wrap {
          width: 100%;
          display: flex;
          flex-direction: column;
          row-gap: 1rem;
        }

        #notice-detail-header {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin: 0;
          user-select: none;
          border-bottom: 1px solid #e5e5e5;
          padding-bottom: 0.5rem;
        }

        #notice-detail-header-info {
          color: #999999;
        }

        #notice-detail-content {
          width: 100%;
          display: flex;
          flex-direction: column;
          row-gap: 1rem;
          border-bottom: 1px solid #e5e5e5;
          padding: 1rem 1.5rem 2rem;
        }

        #notice-detail-footer {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0;
          user-select: none;
          padding: 0 1.5rem;
        }

        @media screen and (max-width: 768px) {
          #notice-detail-container {
            padding: 1.5rem 1rem 8rem;
            row-gap: 1rem;
          }

          #notice-detail-container h2 {
            font-size: 1.25rem;
          }

          #notice-detail-container h3 {
            font-size: 1.125rem;
          }

          #notice-detail-container #notice-detail-header-info {
            display: flex;
            align-items: center;
            font-size: 0.875rem;
          }

          #notice-detail-content {
            padding: 1rem 0.75rem 2rem;
          }

          #notice-detail-footer {
            padding: 0 0.5rem;
          }

          .notice-detail-content-attach-list-item {
            display: flex;
            flex-wrap: wrap !important;
          }

          .notice-detail-content-attach-list-item > span {
            display: none;
          }
        }

        #notice-detail-content-attach {
          width: 100%;
          display: flex;
          flex-direction: column;
          row-gap: 0.5rem;
          border: 1px solid #e5e5e5;
          padding: 1rem;
          margin-top: 1rem;
          border-radius: 0.5rem;
        }

        #notice-detail-content-attach-list {
          width: 100%;
          display: flex;
          column-gap: 1rem;
          row-gap: 0.5rem;
          flex-wrap: wrap;
          overflow: hidden;
        }

        .notice-detail-content-attach-list-item {
          display: flex;
          align-items: center;
          column-gap: 0.5rem;
          color: #777777;
          cursor: pointer;
        }

        .notice-detail-content-attach-list-item:hover {
          color: #000000;
        }

        .notice-detail-content-attach-list-item > span {
          font-size: 0.875rem;
        }

        .notice-detail-content-attach-list-item > svg {
          margin-top: -0.1975rem;
        }

        .login-prompt {
          margin-top: 1rem;
          padding: 1rem;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .login-prompt h2 {
          font-weight: normal !important;
          margin-bottom: 1rem;
          font-size: 24px !important;
        }

        .login-prompt a {
          color: black !important;
          text-decoration: underline;
          cursor: pointer;
        }

        .login-prompt h2 > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .partial-content::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 40rem;
          background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
        }
      `}</style>

      {detail && (
        <div id="notice-detail-container">
          {detail.FK_board.code === 'notice' && (
            <div id="notice-title-wrap">
              <h2>
                <FormattedMessage id="HEADER_USER_NAV_NOTICE" />
              </h2>
            </div>
          )}

          <div id="notice-detail-wrap">
            {detail.FK_board.code === 'notice' && (
              <div id="notice-detail-header">
                <div id="notice-detail-header-title">
                  <h3>{detail.title}</h3>
                </div>
                <div id="notice-detail-header-info">
                  <span>{dayjs(detail.createdAt).format('YYYY-MM-DD')}</span>
                </div>
              </div>
            )}

            <div id="notice-detail-content">
              <div
                className={'ql-container ql-editor' + (detail.needLoginToView && !isLoggedIn ? ' partial-content' : '')}
                dangerouslySetInnerHTML={{
                  __html: detail.content,
                }}
              />

              {detail.needLoginToView && !isLoggedIn && (
                <div className="login-prompt">
                  <h2>
                    <div>
                      <p>다음 내용이 궁금하다면?</p>
                      <p>가입하고 무료로 확인하세요!</p>
                    </div>
                  </h2>

                  <LoginItem redirectURI={location.pathname} />
                </div>
              )}

              {isLoggedIn && detail.Files && detail.Files.length > 0 && (
                <div id="notice-detail-content-attach">
                  <div id="notice-detail-content-attach-title">
                    <FormattedMessage id="NOTICE_DETAIL_ATTACH" defaultMessage="Attached files" />
                  </div>
                  <div id="notice-detail-content-attach-list">
                    {detail.Files.map((file) => (
                      <div
                        key={file.id}
                        className="notice-detail-content-attach-list-item"
                        onClick={async (event) => {
                          event.preventDefault();

                          const res = await PATCH({
                            url: `/auth/files/${file.id}/down-count`,
                          });
                          if (res?.success && file.filePath) {
                            axios(file.filePath, {
                              responseType: 'blob',
                            })
                              .then((response) => {
                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', `${file.fileName}.${file.fileExtension}`);
                                link.style.display = 'none';
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                              })
                              .catch((error) => popupError());
                          } else {
                            popupError();
                          }
                        }}
                      >
                        {renderFileIcon({
                          type: file.fileType,
                          extension: file.fileExtension,
                        })}
                        {file.fileName}.{file.fileExtension}
                        <span>({fileSizeText(file.fileSize)})</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div id="notice-detail-footer">
              {/*  다음 게시글 */}
              {/*<div id="notice-detail-footer-next">*/}
              {/*  {nextPost && (*/}
              {/*    <Link to={page === 1 ? `/notice/${nextPost.id}` : `/notice/${nextPost.id}?page=${page}`}>*/}
              {/*      &lt;&lt; {nextPost.title}*/}
              {/*    </Link>*/}
              {/*  )}*/}
              {/*</div>*/}
              {/*  목록 */}
              <div id="notice-detail-footer-list">
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    backgroundColor: '#999999',
                    '&:hover': {
                      backgroundColor: '#777777',
                    },
                  }}
                  disableElevation
                  disableFocusRipple
                  onClick={() => history.push(+page === 1 ? '/notices' : `/notices?page=${page}`)}
                >
                  <FormattedMessage id="LIST" defaultMessage="List" />
                </Button>
              </div>
              {/*  이전 게시글 */}
              {/*<div id="notice-detail-footer-prev">*/}
              {/*  {prevPost && (*/}
              {/*    <Link to={page === 1 ? `/notice/${prevPost.id}` : `/notice/${prevPost.id}?page=${page}`}>*/}
              {/*      {prevPost.title} &gt;&gt;*/}
              {/*    </Link>*/}
              {/*  )}*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      )}
    </Main>
  );
};

export default Notice;
