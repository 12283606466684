import { Check } from '@mui/icons-material';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import Popup from '../../../Commons/Components/Popup';
import MyPage from '../../../Commons/Layouts/MyPage';
import * as userActions from '../../../Commons/Store/Actions/user';
import { login } from '../../../Commons/Store/Actions/user';
import { DELETE, GET, PUT } from '../../../Commons/Utils/fetch';

import './index.scss';
import CheckIcon from '../../../Assets/Images/icon-checkbox-checked.png';
import UncheckIcon from '../../../Assets/Images/icon-checkbox-unchecked.png';

export default function ProfileWrapper() {
  const history = useHistory();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  useEffect(() => {
    if (!isLoggedIn) {
      sessionStorage.setItem('login_redirect', '/my/profile');
      history.push('/login');
    }
  }, [isLoggedIn, history]);

  const location = useLocation();
  const { didClickSignOut } = location.state || {};

  return (
    <MyPage>
      <div className="profile__wrapper">
        <h3 className="title">
          <FormattedMessage id="MY_PAGE_PROFILE" />
        </h3>
        {didClickSignOut ? <Signout /> : <Profile />}
      </div>
    </MyPage>
  );
}

function DivCheckboxElement(props) {
  const {
    value,
    label,
    subLabel,
    firstMessage,
    secondMessage,
    handleAgreementClick,
    handleDisagreementClick,
    ...otherProps
  } = props;

  return (
    <div className="form-group__field form-group__field-checkbox" {...otherProps}>
      <div className="label-wrap">
        <p className="label">
          <FormattedMessage id={label} />
        </p>
        <p className="label-sub">
          <FormattedMessage id={subLabel} />
        </p>
      </div>

      {(!!value || typeof value === 'boolean') && (
        <div className="checkbox__filed">
          <button type="button" onClick={handleAgreementClick}>
            <span className="checkbox">
              <img src={value ? CheckIcon : UncheckIcon} alt="agreement_marketing" />
            </span>
            <span>
              <FormattedMessage id={firstMessage} />
            </span>
          </button>
          <button type="button" onClick={handleDisagreementClick}>
            <span className="checkbox">
              <img src={!value ? CheckIcon : UncheckIcon} alt="disagreement_marketing" />
            </span>
            <span>
              <FormattedMessage id={secondMessage} />
            </span>
          </button>
        </div>
      )}
    </div>
  );
}

function InputElement(props) {
  const intl = useIntl();
  const {
    name,
    label,
    type,
    register,
    checkMethod,
    checkBtnText,
    hasUpdated,
    isValid,
    errorMessage,
    watchData,
    value,
    placeholder,
    andValid,
  } = props;
  const [hasValidAction, setHasValidAction] = useState(false);
  const [lastCheckValue, setLastCheckValue] = useState('');
  const [checkStatus, setCheckStatus] = useState(false);
  useEffect(() => {
    setHasValidAction(false);
  }, [watchData]);
  return (
    <div className="form-group__field-wrap">
      <div className="form-group__field">
        <label htmlFor={name}>{label ? <FormattedMessage id={label} /> : ''}</label>
        <input
          id={name}
          name={name}
          className={`form-control ${hasUpdated && !isValid && checkBtnText ? 'error' : ''}`}
          type={type}
          {...register}
          placeholder={
            placeholder
              ? intl.formatMessage({
                  id: props.placeholder,
                })
              : ''
          }
        />
        {checkMethod && (
          <div
            className={`btn-check ${hasUpdated ? 'active' : ''} ${isValid ? 'disabled' : ''} ${
              !isValid && andValid ? 'needCheck' : ''
            }`}
            onClick={async () => {
              await checkMethod();
              setLastCheckValue(watchData);
              setHasValidAction(true);
            }}
          >
            {isValid ? <Check /> : <FormattedMessage id={checkBtnText} />}
          </div>
        )}
      </div>
      {errorMessage && (
        <div className="form-group__field-error-wrap">
          <div className="empty-first" />
          <div className={`form-group__field-error ${!isValid && checkBtnText && hasValidAction ? 'active' : ''}`}>
            {errorMessage && <FormattedMessage id={errorMessage} />}
          </div>
          <div />
        </div>
      )}
    </div>
  );
}

function Profile(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user.userInfo);

  const [isMarketingOn, setIsMarketingOn] = useState(null);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      nickname: '',
      name: '',
      email: '',
      is_marketing_on: '',
      password: '',
      passwordConfirm: '',
    },
  });
  const watchNickname = watch('nickname');
  const watchName = watch('name');
  const watchEmail = watch('email');
  const watchPhone = watch('phone');
  const watchIsMarketingOn = watch('is_marketing_on');
  const watchPhoneCert = watch('phoneCert');
  const watchEmailCert = watch('emailCert');

  const intl = useIntl();
  const [user, setUser] = useState({});
  const [userOrigin, setUserOrigin] = useState({});
  const [updated, setUpdated] = useState({
    nickname: false,
    name: false,
    email: false,
    phone: false,
    is_marketing_on: false,
  });
  const [valid, setValid] = useState({
    nickname: true,
    name: true,
    email: true,
    phone: true,
    is_marketing_on: true,
  });
  const [allValid, setAllValid] = useState(true);
  const [hasUpdated, setHasUpdated] = useState(false);

  useEffect(async () => {
    const token = localStorage.getItem('eje_token') || null;
    if (token) {
      const { user: userInfo } = await GET({
        url: `/user/login`,
        header: {
          EJE_API_KEY: token,
        },
      });
      await setUserOrigin(userInfo);
      await setUser(userInfo);

      if (Object.keys(userInfo).length > 0 && userInfo.nickname) {
        setValue('name', userInfo.name);
        setValue('email', userInfo.email);
        setValue('image', userInfo.image_profile);
        setValue('nickname', userInfo.nickname);
        setValue('phone', formatPhoneNumber(userInfo.phone, userInfo));
        setValue('is_marketing_on', userInfo.is_marketing_on);
        setIsMarketingOn(userInfo.is_marketing_on);
      }
    } else {
      history.push('/login');
    }
  }, []);

  const onValid = (data) => {
    const body = {};
    Object.keys(updated).forEach((key) => {
      if (updated[key] && valid[key]) {
        if (key === 'phone') {
          body[key] = globalFormatPhoneNumber(data[key], userOrigin.FK_country_id === 47) || userOrigin[key];
        } else {
          body[key] = data[key];
        }
      } else {
        if (key === 'is_marketing_on') {
          body[key] = userOrigin[key];
        } else {
          body[key] = userOrigin[key];
        }
      }
    });

    PUT({
      url: `/user/${userOrigin.id}`,
      header: {
        EJE_API_KEY: localStorage.getItem('eje_token'),
      },
      body,
    })
      .then((res) => {
        setUser(res.user);
        setUserOrigin(res.user);
        dispatch(
          login({
            ...userInfo,
            ...res.user,
          }),
        );
        swal({
          title: intl.formatMessage({ id: 'MY_PAGE_PROFILE_UPDATE_SUCCESS' }),
          icon: 'success',
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    setUpdated({
      nickname: watchNickname && watchNickname !== userOrigin.nickname,
      name: watchName && watchName !== userOrigin.name,
      email: watchEmail && watchEmail !== userOrigin.email,
      phone: watchPhone && watchPhone !== formatPhoneNumber(userOrigin.phone, userOrigin),
      is_marketing_on:
        (watchIsMarketingOn === true || watchIsMarketingOn === false) &&
        watchIsMarketingOn !== userOrigin.is_marketing_on,
    });
    setHasUpdated(
      watchNickname !== userOrigin.nickname ||
        watchName !== userOrigin.name ||
        watchEmail !== userOrigin.email ||
        watchPhone !== formatPhoneNumber(userOrigin.phone, userOrigin) ||
        watchIsMarketingOn !== userOrigin.is_marketing_on,
    );
  }, [
    userOrigin,
    userOrigin.email,
    userOrigin.name,
    userOrigin.nickname,
    userOrigin.phone,
    userOrigin.is_marketing_on,
    watchEmail,
    watchName,
    watchNickname,
    watchPhone,
    watchIsMarketingOn,
  ]);

  const formatPhoneNumber = (input, userInfo = user) => {
    if (!input) return undefined;
    const cleanInput = input.replaceAll(/\D/g, '');
    let result = '';
    const length = cleanInput.length;
    if (userInfo.FK_country_id === 47) {
      result = cleanInput.replace(/^82/, '0');
      let phoneNumber = '';
      if (result.length <= 3) phoneNumber = result;
      else if (result.length <= 7) phoneNumber = `${result.slice(0, 3)}-${result.slice(3)}`;
      else phoneNumber = `${result.slice(0, 3)}-${result.slice(3, 7)}` + `-${result.slice(7, 11)}`;
      result = phoneNumber;
    } else {
      result = undefined;
    }
    return result;
  };

  const globalFormatPhoneNumber = (phoneNumber, isKorea = false) => {
    if (!phoneNumber) return undefined;
    const cleanPhoneNumber = phoneNumber.replaceAll(/-/g, '');
    let result = '';
    if (isKorea) {
      result = cleanPhoneNumber.replace(/^0/, '82');
    }
    return result;
  };

  const checkNicknameOverlap = async () => {
    let { nickname } = getValues();
    if (!nickname.trim()) return;

    const params = {
      nickname,
    };
    const overlap = await GET({
      url: '/auth/check/nickname',
      params,
      header: {
        EJE_API_KEY: 'auth',
      },
    });

    if (overlap.success) {
      // nickname 중복이 아님
      setValid((prev) => ({ ...prev, nickname: true }));
    } else {
      // nickname 중복
      setValid((prev) => ({ ...prev, nickname: false }));
    }
    return overlap;
  };

  const [hasPhoneCert, setHasPhoneCert] = useState(false);
  const [hasEmailCert, setHasEmailCert] = useState(false);
  const requestSecureCode = async (target, value) => {
    try {
      const { success, message } = await GET({
        url: `/auth/check/${target === 'phone' ? 'phone/sms' : 'email'}`,
        params: {
          phone: value.replaceAll(/-/g, '').trim(),
          email: value.trim(),
          isKorean: userOrigin.FK_country_id === 47,
        },
        header: {
          EJE_API_KEY: 'auth',
        },
      });
      if (success) {
        if (target === 'phone') setHasPhoneCert(true);
        else if (target === 'email') setHasEmailCert(true);
        await swal({
          icon: 'success',
          text: intl.formatMessage({ id: 'SIGNUP_FORM_ALERT_SUCCESS_SEND' }),
        });
      } else {
        if (target === 'phone') setHasPhoneCert(false);
        else if (target === 'email') setHasEmailCert(false);
        if (message) {
          await swal({
            icon: 'error',
            text: intl.formatMessage({ id: message }),
          });
        }
      }
    } catch (e) {
      console.error(e);
      swal({
        icon: 'error',
        text: intl.formatMessage({ id: 'ERROR_NETWORK' }),
      });
    }
  };

  const [checkCertificationPhone, setCheckCertificationPhone] = useState(false);
  const [checkCertificationEmail, setCheckCertificationEmail] = useState(false);
  const onClickCheckCertificationNumber = async (target, code) => {
    if (!code.trim()) return;
    if (code.length !== 6) {
      swal({
        icon: 'warning',
        text: intl.formatMessage({
          id: 'SIGNUP_FORM_AUTHENTICATE_ERROR',
        }),
      });
      return;
    }

    try {
      const params = {
        code,
      };

      const checkResponse = await GET({
        url: '/auth/check/phone',
        params,
        header: {
          EJE_API_KEY: 'auth',
        },
      });
      const { success } = checkResponse;
      if (target === 'phone') setCheckCertificationPhone(success);
      else if (target === 'email') setCheckCertificationEmail(success);
      if (success) {
        if (target === 'phone') setValid((prev) => ({ ...prev, phone: true }));
        else if (target === 'email') setValid((prev) => ({ ...prev, email: true }));
        await swal({
          icon: 'success',
          text: intl.formatMessage({
            id: 'SIGNUP_FORM_ALERT_SUCCESS_CHECK_CERTIFICATION',
          }),
        });
      } else {
        swal({
          icon: 'error',
          text: intl.formatMessage({
            id: 'SIGNUP_FORM_ALERT_FAIL_CHECK_CERTIFICATION',
          }),
        });
      }
    } catch (error) {
      console.error(error);
      swal({
        icon: 'error',
        text: intl.formatMessage({
          id: 'ERROR_NETWORK',
        }),
      });
    } finally {
      if (target === 'phone') setValue('phoneCert', '');
      else if (target === 'email') setValue('emailCert', '');
    }
  };

  const handleMarketingAgreementClick = () => {
    setIsMarketingOn(true);
    setValue('is_marketing_on', true);
  };

  const handleMarketingDisagreementClick = () => {
    setIsMarketingOn(false);
    setValue('is_marketing_on', false);
  };

  useEffect(() => {
    setValid((prev) => ({ ...prev, email: !updated.email }));
  }, [watchEmail, updated.email]);

  useEffect(() => {
    setValue('phone', formatPhoneNumber(watchPhone, userOrigin));
    setValid((prev) => ({ ...prev, phone: !updated.phone }));
  }, [watchPhone, updated.phone]);

  useEffect(() => {
    setHasPhoneCert(valid.phone);
  }, [valid.phone]);
  useEffect(() => {
    setHasEmailCert(valid.email);
  }, [valid.email]);

  useEffect(() => {
    if (!updated.nickname) setValid((prev) => ({ ...prev, nickname: true }));
    else setValid((prev) => ({ ...prev, nickname: false }));
  }, [watchNickname, updated.nickname]);

  useEffect(() => {
    setAllValid(valid.nickname && valid.name && valid.email && valid.phone && valid.is_marketing_on);
  }, [valid]);

  return (
    <div className="profile">
      <form onSubmit={handleSubmit(onValid)}>
        <div className="form-group">
          <InputElement
            type="text"
            register={register('nickname', {
              required: true,
              maxLength: 10,
            })}
            name="nickname"
            label="MY_PAGE_PROFILE_NICKNAME"
            placeholder="SIGNUP_FORM_NICKNAME_PLACEHOLDER"
            maxLength={10}
            checkMethod={() => checkNicknameOverlap()}
            checkBtnText="SIGNUP_FORM_CHECK_OVERLAP"
            isValid={valid.nickname}
            hasUpdated={updated.nickname}
            errorMessage="SIGNUP_FORM_ALERT_FAIL_NICKNAME"
            watchData={watchNickname}
          />
          <InputElement
            type="text"
            register={register('name', {
              required: true,
            })}
            name="name"
            label="MY_PAGE_PROFILE_NAME"
            placeholder="SIGNUP_FORM_USERNAME_PLACEHOLDER"
            isValid={valid.name}
            hasUpdated={updated.name}
          />
          {userOrigin.FK_country_id === 47 && (
            <>
              <InputElement
                type="text"
                register={register('phone', {
                  required: true,
                  maxLength: 13,
                })}
                name="phone"
                label="MY_PAGE_PROFILE_PHONE"
                placeholder="SIGNUP_FORM_PHONE_NUMBER_PLACEHOLDER"
                maxLength={13}
                checkMethod={() => requestSecureCode('phone', watchPhone)}
                checkBtnText="SIGNUP_FORM_SEND_VERIFICATION_CODE"
                validMethod={() => {}}
                validBtnText="SIGNUP_FORM_AUTHENTICATE"
                isValid={valid.phone}
                andValid={hasPhoneCert}
                hasUpdated={updated.phone}
              />
              <div className={`validation-secure-code ${hasPhoneCert && !valid.phone ? 'active' : ''}`}>
                <InputElement
                  type="text"
                  register={register('phoneCert')}
                  name="phoneCert"
                  label=""
                  placeholder="SIGNUP_FORM_AUTHENTICATE_PLACEHOLDER"
                  checkMethod={() => onClickCheckCertificationNumber('phone', watchPhoneCert)}
                  checkBtnText="SIGNUP_FORM_AUTHENTICATE"
                  hasUpdated={true}
                />
              </div>
            </>
          )}
          <InputElement
            type="email"
            register={register('email', {
              validate: (value) => /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/.test(value),
              type: 'email',
            })}
            name="email"
            label="MY_PAGE_PROFILE_EMAIL"
            placeholder="SIGNUP_FORM_EMAIL_PLACEHOLDER"
            checkMethod={() => requestSecureCode('email', watchEmail)}
            checkBtnText="SIGNUP_FORM_SEND_VERIFICATION_CODE"
            validMethod={() => {}}
            validBtnText="SIGNUP_FORM_AUTHENTICATE"
            isValid={valid.email}
            andValid={hasEmailCert}
            hasUpdated={updated.email}
          />
          <div className={`validation-secure-code ${hasEmailCert && !valid.email ? 'active' : ''}`}>
            <InputElement
              type="text"
              register={register('emailCert')}
              name="emailCert"
              label=""
              placeholder="SIGNUP_FORM_AUTHENTICATE_PLACEHOLDER"
              checkMethod={() => onClickCheckCertificationNumber('email', watchEmailCert)}
              checkBtnText="SIGNUP_FORM_AUTHENTICATE"
              hasUpdated={true}
            />
          </div>
          <DivCheckboxElement
            id="is_marketing_on"
            value={isMarketingOn}
            label="MY_PAGE_LABEL_IS_MARKETING_ON"
            subLabel="MY_PAGE_LABEL_IS_MARKETING_ON_MESSAGE"
            handleAgreementClick={handleMarketingAgreementClick}
            handleDisagreementClick={handleMarketingDisagreementClick}
            firstMessage="SIGNUP_FORM_TERMS_AGREEMENT"
            secondMessage="SIGNUP_FORM_TERMS_DISAGREE"
          />
        </div>
        <button className="save_btn" type="submit" disabled={!hasUpdated || !allValid}>
          <FormattedMessage id="MY_PAGE_SAVE" />
        </button>
      </form>
      <Link className="signout_link" to={{ state: { didClickSignOut: true } }}>
        <FormattedMessage id="MY_PAGE_PROFILE_SIGNOUT" />
      </Link>
      <style jsx="true">
        {`
          form {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 24px;
            justify-content: center;
            row-gap: 120px;
          }

          form .form-group {
            display: flex;
            flex-direction: column;
            margin-bottom: 24px;
            width: 100%;
            row-gap: 20px;
          }

          .profile {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0 20px;
          }

          .save_btn {
            width: 120px;
            height: 42px;
            padding: 0 16px;
            background-color: #f24462;
            color: #fff;
            border: none;
            border-radius: 5px;
            font-size: 14px;
            font-weight: bold;
            cursor: pointer;
            margin: 0 auto;
          }

          .save_btn:disabled {
            opacity: 0.5;
            pointer-events: none;
          }

          .validation-secure-code {
            overflow: hidden;
            height: 0;
            margin-top: -20px;
            transition: all 0.3s ease-in-out;
          }

          .validation-secure-code.active {
            height: 42px;
            margin-top: -10px;
            transition: all 0.3s ease-in-out;
          }
        `}
      </style>
    </div>
  );
}

function Signout(props) {
  const intl = useIntl();
  const [agreed, setAgreed] = useState(false);
  const [open, setOpen] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const onClickConfirm = useCallback(() => {
    DELETE({
      url: '/user/signout',
      header: { EJE_API_KEY: localStorage.getItem('eje_token') },
    }).then(() => {
      history.push('/');
      dispatch(userActions.logout());
    });
  }, [history, dispatch]);
  return (
    <div className="signout">
      <p className="message">
        <FormattedMessage id="MY_PAGE_SIGNOUT_MESSAGE" />
      </p>
      <ul className="terms">
        <li className="term">
          <FormattedMessage id="MY_PAGE_SIGNOUT_TERM_1" />
        </li>
        <li className="term">
          <FormattedMessage id="MY_PAGE_SIGNOUT_TERM_2" />
        </li>
        <li className="term">
          <FormattedMessage id="MY_PAGE_SIGNOUT_TERM_3" />
        </li>
      </ul>
      <label className="agree">
        <input type="checkbox" checked={agreed} onChange={(e) => setAgreed(e.target.checked)} />
        <div className="checkbox-img" />
        <span>
          <FormattedMessage id="MY_PAGE_SIGNOUT_TERM_AGREE" />
        </span>
      </label>
      <a className="form_link" href="https://forms.gle/R1nMiewafxuuW7rN7" target="_blank" rel="noreferrer">
        <span>
          <FormattedMessage id="MY_PAGE_SIGNOUT_FORM" />
        </span>
      </a>
      <button className="signout_btn" onClick={() => setOpen(true)} disabled={!agreed}>
        <FormattedMessage id="MY_PAGE_SIGNOUT_CONFIRM" />
      </button>
      <Popup open={open} onClose={() => setOpen(false)} className="mobile-popup" modal>
        <div className="popup-title">
          {intl.formatMessage({
            id: 'MY_PAGE_PROFILE_SIGNOUT',
          })}
        </div>
        <div className="popup-msg">
          <FormattedMessage id="MY_PAGE_SIGNOUT_POLICY" />
        </div>
        <div className="popup-buttons">
          <button className="popup-button--cancel" onClick={() => setOpen(false)}>
            <FormattedMessage id="COMMON_CANCEL" />
          </button>
          <button onClick={onClickConfirm}>
            <FormattedMessage id="COMMON_CONFIRM" />
          </button>
        </div>
      </Popup>
    </div>
  );
}
