import dayjs from 'dayjs';
import queryString from 'query-string';
import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { TRANSACTION } from '../../Commons/Utils/constant';
import { GET, POST } from '../../Commons/Utils/fetch';
import updateState from '../../Commons/Utils/UpdateState';
import View from './View';
import { LECTURE } from '../LectureDetail/constant';
import Swal from 'sweetalert2';

const { PAID, READY, FAILED } = TRANSACTION.TYPE.STATUS;

class Container extends PureComponent {
  PAYMENT_IMPLEMENTS = [
    {
      key: 'PAYMENT_IMPLEMENTS_CREDIT_OR_CHECK_CARD',
      pg: 'html5_inicis',
      pay_method: 'card',
    },
    {
      key: 'PAYMENT_IMPLEMENTS_V_BANK',
      pg: 'html5_inicis',
      pay_method: 'vbank',
    },
    {
      key: 'PAYMENT_IMPLEMENTS_KAKAOPAY',
      pg: 'html5_inicis',
      pay_method: 'kakaopay',
    },
    {
      key: 'PAYMENT_IMPLEMENTS_TOSS',
      pg: 'html5_inicis',
      pay_method: 'tosspay',
    },
  ];
  state = {
    isLoading: true,
    lecture: null,
    paymentImplementIndex: null,
    agreementTerms: false,
    isValidation: false,
    hasDiscount: false,
    hasOptionPrice: false,
    discountedPrice: 0, // 강의 금액(할인률, 옵션가 적용된)
    classDiscountAmount: 0, // 강의 자체 할인 금액
    couponDiscountAmount: 0, // 쿠폰 할인 금액(rate / amount)
    classOptionPrice: null, // 온라인(기수제) 옵션 금액
    totalApplyDiscountedPrice: 0, // 최종 결제 금액
    isShowCouponSelectOptions: false,
    userCouponList: [], // 해당 강의 결제시 사용 가능한 쿠폰,
    selectedCouponIndex: undefined,
    implementList: this.PAYMENT_IMPLEMENTS,
  };

  constructor(props) {
    super(props);

    // 오프라인 강의 경우, 기수 ID
    this.class_offline_schedule_id = queryString.parse(window.location.search).class_offline_schedule_id || null;

    // 컨설팅 강의 경우
    this.class_consulting_schedule_id = queryString.parse(window.location.search).class_consulting_schedule_id || null;

    // 온라인(기수제) 강의 경우
    this.class_online_schedule_id = queryString.parse(window.location.search).class_online_schedule_id || null;
  }

  /** Life Cycle */
  async componentDidMount() {
    await this.getLecture();
    await this.setPrices();
    this.getUserCoupons();
  }

  componentDidUpdate() {
    const { paymentImplementIndex, agreementTerms, totalApplyDiscountedPrice } = this.state;

    if (paymentImplementIndex !== null && agreementTerms) {
      this.setState({
        isValidation: true,
      });
    } else if (!totalApplyDiscountedPrice && agreementTerms) {
      this.setState({
        isValidation: true,
      });
    } else {
      this.setState({
        isValidation: false,
      });
    }
  }

  /** Fetchs */
  getLecture = async () => {
    const { id } = this.props.match.params;
    this.setState({ isLoading: true });

    const { classModel, isOnOtherSchedule } = await GET({
      url: `/payment/class/${id}`,
      header: {
        EJE_API_KEY: localStorage.getItem('eje_token'),
      },
      params: {
        scheduleId:
          this.class_offline_schedule_id || this.class_consulting_schedule_id || this.class_online_schedule_id,
      },
    });

    if (!classModel) {
      await swal({
        icon: 'warning',
        text: this.props.intl.formatMessage({
          id: 'PAYMENT_ALREADY_BEEN_PAID',
        }),
      });
      this.props.history.replace('/my/lectures');
      return;
    }

    if (!classModel.is_selling) {
      await swal({
        icon: 'warning',
        text: this.props.intl.formatMessage({
          id: 'DETAIL_LECTURE_NOT_SELLiNG',
        }),
      });
      this.props.history.push('/');
      return;
    }

    if (isOnOtherSchedule) {
      const confirmRes = await Swal.fire({
        title: '다른 옵션으로 결제한 강의입니다.',
        text: '계속해서 결제를 진행할까요?',
        confirmButtonText: '확인',
        confirmButtonColor: '#F24462',
        showCancelButton: true,
        cancelButtonText: '취소',
        cancelButtonColor: '#A2A2A2',
        icon: 'info',
        footer:
          '<a href="/my/lectures" style="color: #F24462; text-decoration: none; font-weight: bold;">내 강의실로 이동</a>',
      });

      if (!confirmRes.isConfirmed) {
        this.props.history.goBack();
      }
    }

    await updateState(this, {
      lecture: classModel,
      isLoading: false,
    });
  };

  setPrices = async () => {
    const { lecture } = this.state;
    if (!lecture) return;

    try {
      let discountedPrice = 0; // 강의 자체 할인률, 옵션가 적용된 금액
      let classDiscountAmount = 0; // 강의 자체 할인 금액
      let classOptionPrice = null; // 온라인(기수제) 옵션 금액

      // 할인률 적용된 강의 금액
      if (lecture.isDiscountAvailable) {
        const discountedAmount = (parseInt(lecture.price_original) * (100 - lecture.discount_rate)) / 100;

        classDiscountAmount = lecture.price_original * (lecture.discount_rate / 100);

        discountedPrice = this.getConvertedPrice(discountedAmount); // 100원 단위까지 반올림, % 할인률 적용된 강의 가격
        classDiscountAmount = this.getConvertedPrice(classDiscountAmount);
      } else {
        discountedPrice = this.getConvertedPrice(lecture.price_original);
      }
      if (LECTURE.ROUND_LISTEN_TYPES.includes(lecture.listen_type)) {
        if (this.props.location.state?.option_price) {
          classOptionPrice = this.props.location.state?.option_price;
          discountedPrice = this.getConvertedPrice(discountedPrice + classOptionPrice);
        } else {
          if (this.props.location.state?.option_price !== 0) throw Error('강의 옵션을 선택해주세요!');
          else classOptionPrice = this.props.location.state?.option_price;
        }
      }
      this.setState({
        hasDiscount: lecture.isDiscountAvailable, // 할인 유지 여부
        hasOptionPrice: this.props.location.state?.option_price > 0 || this.props.location.state?.option_price < 0,
        discountedPrice,
        classOptionPrice,
        totalApplyDiscountedPrice: discountedPrice, // 쿠폰 선택되지 않은 이상 강의 금액 = 결제 금액
        classDiscountAmount,
        implementList:
          lecture.listen_type === 'foreigner'
            ? [
                {
                  key: 'PAYMENT_IMPLEMENTS_PAYPAL',
                  pg: 'paypal',
                  pay_method: 'card',
                },
              ]
            : this.PAYMENT_IMPLEMENTS,
      });
    } catch (error) {
      console.error(error);
      await swal({
        icon: 'error',
        text: error.message,
      });

      this.props.history.goBack();
    }
  };

  getUserCoupons = async () => {
    const { id } = this.props.match.params;
    const { userCoupons } = await GET({
      url: `/payment/coupons`,
      params: {
        class_id: id,
      },
      header: {
        EJE_API_KEY: localStorage.getItem('eje_token'),
      },
    });
    const { lecture, totalApplyDiscountedPrice } = this.state;

    // 해당 강의에서 사용가능한 쿠폰 리스트
    let availableCoupon = userCoupons ? userCoupons : [];

    availableCoupon = availableCoupon.filter((coupon) => {
      /** 가격이 실제로 깍이는 쿠폰만 노출 */
      if (lecture?.listen_type !== 'foreigner') {
        // 쿠폰 할인 가격 < 50원 의 경우 쿠폰 노출, 현재 강의 가격 === 100 할인율 100퍼 쿠폰만 노출
        if (coupon.FK_coupon.type === 'price') {
          return true;
        }

        if (coupon.FK_coupon.type === 'rate') {
          const couponDiscountedPrice =
            totalApplyDiscountedPrice -
            (totalApplyDiscountedPrice - totalApplyDiscountedPrice * (coupon.FK_coupon.amount / 100));
          if (totalApplyDiscountedPrice === 100) {
            return coupon.FK_coupon.amount === 100;
          } else return couponDiscountedPrice > 50;
        }

        return false;
      } else {
        const couponDiscountedPrice =
          totalApplyDiscountedPrice -
          (totalApplyDiscountedPrice - totalApplyDiscountedPrice * (coupon.FK_coupon.amount / 100));
        return couponDiscountedPrice >= 1;
      }
      return true;
    });

    this.setState({
      userCouponList: availableCoupon,
    });
  };

  postTransaction = async (body) => {
    try {
      const { transaction } = await POST({
        url: '/payment/transaction',
        body,
        header: {
          EJE_API_KEY: localStorage.getItem('eje_token'),
        },
      });

      if (transaction) {
        return transaction;
      }

      throw Error('transaction is not valid');
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  postUserClass = async (body) => {
    try {
      const { success } = await POST({
        url: '/payment/userclass',
        body,
        header: {
          EJE_API_KEY: localStorage.getItem('eje_token'),
        },
      });

      return success;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  postFreeTransaction = async (body) => {
    try {
      const { transaction } = await POST({
        url: '/payment/transaction/free',
        body,
        header: {
          EJE_API_KEY: localStorage.getItem('eje_token'),
        },
      });

      if (transaction) {
        return transaction;
      }

      throw Error('transaction is not valid');
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  requestPayment = async () => {
    try {
      /** 결제 요청 데이터 EJE DB 추가 */
      const { userInfo } = this.props;
      const {
        lecture,
        userCouponList,
        selectedCouponIndex,
        classDiscountAmount,
        couponDiscountAmount,
        classOptionPrice,
        implementList,
      } = this.state;

      const isSelectCoupon = Number.isInteger(selectedCouponIndex);
      const selectedCoupon = isSelectCoupon ? userCouponList[selectedCouponIndex] : undefined;

      const { pay_method, pg } = implementList[this.state.paymentImplementIndex];
      const payInfo = {
        pay_method,
        pg,
        class_discount_amount: classDiscountAmount,
        coupon_discount_amount: couponDiscountAmount,
        class_option_price: classOptionPrice,
        paid_amount: this.state.totalApplyDiscountedPrice,
        coupon_id: selectedCoupon ? selectedCoupon.FK_coupon_id : undefined,
      };

      const transaction = await this.postTransaction(payInfo);

      if (!transaction) {
        swal({
          icon: 'error',
          text: this.props.intl.formatMessage({
            id: 'PAYMENT_ERROR',
          }),
        });
        return;
      }

      const { merchant_uid, paid_amount } = transaction;

      const userClassBody = {
        merchant_uid,
        class_id: lecture.id,
      };

      switch (lecture.listen_type) {
        case 'offline':
          userClassBody['class_offline_schedule_id'] = this.class_offline_schedule_id;
          break;
        case 'consulting':
          userClassBody['class_consulting_schedule_id'] = this.class_consulting_schedule_id;
          break;
        case 'roundOnline':
          userClassBody['class_online_schedule_id'] = this.class_online_schedule_id;
          break;
        default:
          break;
      }

      const isSuccessUserClass = await this.postUserClass(userClassBody);

      if (!isSuccessUserClass) {
        swal({
          icon: 'error',
          text: this.props.intl.formatMessage({
            id: 'PAYMENT_ERROR_REQUEST',
          }),
        });

        return;
      }

      /** Iamport 결제 요청 */
      /* 2. 결제 데이터 정의하기 */
      const iamportParam = {
        pg, // PG사
        pay_method, // 결제수단
        merchant_uid, // 주문번호
        name:
          (process.env.REACT_APP_ENV === 'DEV' ? '[DEV] ' : '') +
          this.state.lecture.title.replace(
            /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
            '',
          ), // 주문명
        amount: paid_amount, // 결제금액
        buyer_email: userInfo.email, // 구매자 이메일
        buyer_name: userInfo.name || userInfo.nickname, // 구매자 이름
        buyer_tel: userInfo.phone, // 구매자 전화번호
        buyer_addr: '', // 구매자 주소
        buyer_postcode: '', // 구매자 우편번호
        m_redirect_url: `${process.env.REACT_APP_HOST}/payment/complete`,
        notice_url: `${process.env.REACT_APP_API_SERVER}/payment/iamport`,
      };

      if (pay_method === 'vbank') {
        iamportParam.vbank_due = dayjs().add(3, 'd');
      }

      /* 1. 가맹점 식별하기 */
      const IMP = window.IMP;
      IMP.init('imp92743153');

      /* 4. 결제 창 호출하기 */
      IMP.request_pay(iamportParam, (rsp) => {
        /* 3. 콜백 함수 정의하기 */
        if (rsp.success) {
          // 결제 완료 / 주문 완료 라우팅 => params(uid) 통해 완료 페이지에서 API 호출

          let status = FAILED;

          let vbackInfo;
          if (rsp.success) {
            if (rsp.vbank_num) {
              status = READY;
              const { vbank_num, vbank_name, vbank_holder } = rsp;
              vbackInfo = {
                vbank_num,
                vbank_name,
                vbank_holder,
              };
            } else {
              status = PAID;
            }
          } else {
            status = FAILED;
          }

          this.props.history.push(
            `/payment/complete?status=${status}&merchant_uid=${rsp.merchant_uid}${
              vbackInfo ? '&vbankInfo=' + JSON.stringify(vbackInfo) : ''
            }`,
          );
        } else {
          // 결제 에러 메세지
          // TODO: 디자인 요청
          swal({
            icon: 'error',
            text:
              this.props.intl.formatMessage({
                id: 'PAYMENT_ERROR_2',
              }) +
              ' ' +
              rsp.error_msg,
          });
        }
      });
    } catch (error) {
      console.error('결제 에러');
    }
  };

  requestPaymentForFree = async () => {
    try {
      /** 결제 요청 데이터 EJE DB 추가 */
      const {
        lecture,
        userCouponList,
        selectedCouponIndex,
        classDiscountAmount,
        couponDiscountAmount,
        classOptionPrice,
      } = this.state;

      const isSelectCoupon = Number.isInteger(selectedCouponIndex);
      const selectedCoupon = isSelectCoupon ? userCouponList[selectedCouponIndex] : undefined;

      const pg = 'html5_inicis',
        pay_method = 'card';

      const payInfo = {
        pg,
        pay_method,
        class_discount_amount: classDiscountAmount,
        coupon_discount_amount: couponDiscountAmount,
        class_option_price: classOptionPrice,
        paid_amount: this.state.totalApplyDiscountedPrice,
        coupon_id: selectedCoupon ? selectedCoupon.FK_coupon_id : undefined,
        class_id: lecture.id,
        option_price: this.props.location.state?.option_price,
      };

      switch (lecture.listen_type) {
        case 'offline':
          payInfo['class_offline_schedule_id'] = this.class_offline_schedule_id;
          break;
        case 'consulting':
          payInfo['class_consulting_schedule_id'] = this.class_consulting_schedule_id;
          break;
        case 'roundOnline':
          payInfo['class_online_schedule_id'] = this.class_online_schedule_id;
          break;
        default:
          break;
      }

      const transaction = await this.postFreeTransaction(payInfo);

      if (!transaction) {
        swal({
          icon: 'error',
          text: this.props.intl.formatMessage({
            id: 'PAYMENT_ERROR',
          }),
        });
        return;
      }

      const { merchant_uid } = transaction;

      const userClassBody = {
        merchant_uid,
        class_id: lecture.id,
      };

      switch (lecture.listen_type) {
        case 'offline':
          userClassBody['class_offline_schedule_id'] = this.class_offline_schedule_id;
          break;
        case 'consulting':
          userClassBody['class_consulting_schedule_id'] = this.class_consulting_schedule_id;
          break;
        case 'roundOnline':
          userClassBody['class_online_schedule_id'] = this.class_online_schedule_id;
          break;
        default:
          break;
      }

      const isSuccessUserClass = await this.postUserClass(userClassBody);

      if (!isSuccessUserClass) {
        swal({
          icon: 'error',
          text: this.props.intl.formatMessage({
            id: 'PAYMENT_ERROR_REQUEST',
          }),
        });
      } else {
        this.props.history.push(`/payment/complete?status=paid&merchant_uid=${merchant_uid}`);
      }
    } catch (error) {
      console.error('결제 에러');
    }
  };

  /** Events */
  onClickPaymentImplement = (implementIndex) => {
    this.setState({
      paymentImplementIndex: implementIndex,
    });
  };

  onClickToggleAgreementTerms = () => {
    this.setState((prevState) => ({
      ...prevState,
      agreementTerms: !prevState.agreementTerms,
    }));
  };

  onClickPayment = () => {
    const { totalApplyDiscountedPrice, discountedPrice } = this.state;
    const { userInfo } = this.props;
    const { role, permmission_level } = userInfo || {};
    const permissionLevel = role === 'user' ? permmission_level : 0;
    if (permissionLevel === -2 || (permissionLevel === -1 && discountedPrice <= 0)) {
      // 결제 불가
      swal({
        icon: 'error',
        title: '결제 불가',
        text: '관리자에게 문의해주세요.',
      });
      return;
    }

    if (totalApplyDiscountedPrice <= 0) {
      // TODO: 0원 결제 API 생성하여 반영하기(콘텐츠 지급 참고하여 로직 생성)

      this.requestPaymentForFree();
    }
    this.requestPayment();
  };

  onClickShowCouponOptions = () => {
    this.setState((prev) => ({
      ...prev,
      isShowCouponSelectOptions: !prev.isShowCouponSelectOptions,
    }));
  };

  onClickStopPropagation = (e) => {
    e.stopPropagation();
  };

  onClickSelectUserCoupon = (index) => {
    const { userCouponList, discountedPrice } = this.state;
    const selectedCoupon = userCouponList[index];

    let selectedCouponDiscountAmount = 0;

    if (selectedCoupon.FK_coupon.type === 'rate') {
      selectedCouponDiscountAmount = discountedPrice * (selectedCoupon.FK_coupon.amount / 100); // 할인 적용된 강의 가격 / 쿠폰%
    } else {
      selectedCouponDiscountAmount = selectedCoupon.FK_coupon.amount;
    }

    if (discountedPrice - selectedCouponDiscountAmount < 0) {
      selectedCouponDiscountAmount = discountedPrice;
    }

    const totalPrice =
      discountedPrice - selectedCouponDiscountAmount >= 0
        ? this.getConvertedPrice(discountedPrice - selectedCouponDiscountAmount)
        : 0; // 할인 적용된 강의 가격 - 쿠폰 = 토탈 결제 금액 => 100원 단위까지 반올림 (100원 이상 가정, 100원 이하 0원)

    this.setState({
      selectedCouponIndex: index,
      isShowCouponSelectOptions: false,
      couponDiscountAmount: selectedCouponDiscountAmount,
      totalApplyDiscountedPrice: totalPrice,
    });
  };

  onClickCouponSelectOptionsOutside = (e) => {
    if (!this.state.isShowCouponSelectOptions) return;
    this.setState({
      isShowCouponSelectOptions: false,
    });
  };

  onClickClearSelectCoupon = (e) => {
    e.stopPropagation();

    this.setState({
      selectedCouponIndex: undefined,
      couponDiscountAmount: 0,
      totalApplyDiscountedPrice: this.state.discountedPrice,
    });
  };

  /** Helper Methods */
  getConvertedPrice = (price) => {
    const { lecture } = this.state;
    let _price = Math.round(price / 100) * 100;
    if (lecture && lecture.listen_type === 'foreigner') {
      return Math.round(price);
    }
    return _price;
  };

  render() {
    const {
      onClickPaymentImplement,
      onClickToggleAgreementTerms,
      onClickPayment,
      onClickShowCouponOptions,
      onClickSelectUserCoupon,
      onClickStopPropagation,
      onClickCouponSelectOptionsOutside,
      onClickClearSelectCoupon,
    } = this;
    const {
      isLoading,
      lecture,
      paymentImplementIndex,
      agreementTerms,
      isValidation,
      hasDiscount,
      hasOptionPrice,
      discountedPrice,
      totalApplyDiscountedPrice,
      isShowCouponSelectOptions,
      selectedCouponIndex,
      userCouponList,
      implementList,
    } = this.state;

    return (
      <View
        isLoading={isLoading}
        lecture={lecture}
        hasDiscount={hasDiscount}
        hasOptionPrice={hasOptionPrice}
        discountedPrice={discountedPrice}
        selectedCouponIndex={selectedCouponIndex}
        totalApplyDiscountedPrice={totalApplyDiscountedPrice}
        onClickShowCouponOptions={onClickShowCouponOptions}
        isShowCouponSelectOptions={isShowCouponSelectOptions}
        onClickPaymentImplement={onClickPaymentImplement}
        onClickToggleAgreementTerms={onClickToggleAgreementTerms}
        paymentImplementIndex={paymentImplementIndex}
        agreementTerms={agreementTerms}
        isValidation={isValidation}
        onClickPayment={onClickPayment}
        implementList={implementList}
        userCouponList={userCouponList}
        onClickSelectUserCoupon={onClickSelectUserCoupon}
        onClickStopPropagation={onClickStopPropagation}
        onClickCouponSelectOptionsOutside={onClickCouponSelectOptionsOutside}
        onClickClearSelectCoupon={onClickClearSelectCoupon}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.user.userInfo,
  isLoggedIn: state.user.isLoggedIn,
});

export default connect(mapStateToProps)(injectIntl(Container));
