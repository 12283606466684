const vi = {
  ID_TITLE: 'Enterjobedu',
  LOGIN_TITLE: 'Đăng nhập vào Enterjobedu',
  LOGIN_KAKAO: 'Bắt đầu bằng Kakao',
  LOGIN_NAVER: 'Bắt đầu bằng Naver',
  LOGIN_GOOGLE: 'Bắt đầu bằng Google',
  LOGIN_FACEBOOK: 'Bắt đầu bằng Facebook',
  LOGIN_EMAIL: 'Đăng nhập tài khoản',
  LOGIN: 'Đăng nhập',
  SIGNUP_TITLE: 'Đăng ký thành viên Enterjobedu',
  SIGNUP_MESSAGE: 'Xin gửi tặng bạn ngay 2000 điểm có thể sử dụng được luôn!',
  SIGNUP_KAKAO: 'Bắt đầu bằng Kakao',
  SIGNUP_GOOGLE: 'Bắt đầu bằng Google',
  SIGNUP_NAVER: 'Bắt đầu bằng Naver',
  SIGNUP_FACEBOOK: 'Bắt đầu bằng Facebook',
  SIGNUP_TERMS: 'Nếu tiếp tục thì sẽ coi như bạn đồng ý với {tos} và {privacy} của Enterjobedu.',
  SIGNUP_FORM_TITLE: 'Đăng ký thành viên',
  SIGNUP_FORM_MESSAGE: 'Sau khi hoàn tất đăng ký thì bạn sẽ được tặng Coupon giảm giá 2000!',
  SIGNUP_FORM_SUB_MESSAGE: '(Trong vòng 24 giờ tính theo ngày làm việc)',
  SIGNUP_FORM_PROFILE_IMAGE: 'Ảnh đại diện',
  SIGNUP_FORM_NICKNAME: 'Biệt danh (Cần thiết)',
  SIGNUP_FORM_NICKNAME_PLACEHOLDER: 'Hãy nhập vào không quá 10 ký tự (tiếng Hàn, tiếng Anh).',
  SIGNUP_FORM_CHECK_OVERLAP: 'Kiểm tra trùng lặp',
  SIGNUP_FORM_EMAIL: 'Email (Cần thiết)',
  SIGNUP_FORM_EMAIL_PLACEHOLDER: 'Ví dụ: enterjobedu@k-lab.me',
  SIGNUP_FORM_PHONE_NUMBER: 'Số di động (Cần thiết)',
  SIGNUP_FORM_PHONE_NUMBER_PLACEHOLDER: 'Hãy chỉ nhập số mà không có ‘-‘.',
  SIGNUP_FORM_SEND_VERIFICATION_CODE: 'Gửi số chứng nhận',
  SIGNUP_FORM_AUTHENTICATE: 'Chứng nhận',
  SIGNUP_FORM_AUTHENTICATE_PLACEHOLDER: 'Hãy nhập số chứng nhận vào.',
  SIGNUP_FORM_TERMS: 'Đồng ý tiếp nhận thông tin ưu đãi (Cần thiết)',
  SIGNUP_FORM_TERMS_MESSAGE: 'Bạn có thể nhận được nhiều thông tin đa dạng từ Enterjobedu.',
  SIGNUP_FORM_TERMS_AGREEMENT: 'Đồng ý',
  SIGNUP_FORM_TERMS_DISAGREE: 'Không đồng ý',
  SIGNUP_FORM_COMPLETED: 'Hoàn tất đăng ký',
  HEADER_USER_NAV_LOGIN: 'Đăng nhập',
  HEADER_USER_NAV_LOGOUT: 'Đăng xuất',
  HEADER_USER_NAV_SIGNUP: 'Đăng ký thành viên',
  HEADER_USER_NAV_MY_PAGE: 'Trang của tôi',
  HEADER_USER_NAV_INQUIRY: 'Hỏi đáp 1:1',
  HEADER_USER_NAV_NOTICE: 'Thông báo',
  HEADER_USER_NAV_EVENT: 'Sự kiện',
  HEADER_USER_NAV_BLOG: 'bläɡ',
  HEADER_INSTRUCTOR_INTRODUCTION: 'Giới thiệu giảng viên',
  HEADER_GLOBAL_NAV_ONLINE: 'Online Class',
  HEADER_GLOBAL_NAV_OFFLINE: 'Offline Class',
  HEADER_GLOBAL_NAV_FOREIGNER: 'Foreigner Only',
  HEADER_GLOBAL_NAV_GOV: '🎖 정부지원교육',
  FOOTER_NAV_ABOUT_US: 'Giới thiệu công ty',
  FOOTER_NAV_INSTRUCTOR_RECRUITMENT: 'Tuyển dụng giảng viên',
  FOOTER_NAV_FAQ: 'Câu hỏi thường gặp',
  FOOTER_NAV_PRIVACY_STATEMENT: 'Chính sách xử lý thông tin cá nhân',
  FOOTER_NAV_TERMS: 'Điều khoản sử dụng',
  FOOTER_COMPANY_REGISTRATION_NUMBER: 'Số đăng ký kinh doanh 408-87-01470',
  FOOTER_BUSINESS_REPORT_NUMBER: 'Số đăng ký ngành nghề thương mại điện tử 2021-서울광진-2052',
  FOOTER_LIFELONG_EDUCATION_FACILITY:
    'Cơ sở giáo dục trọn đời, Phòng 626, 1193, đường Jungang, quận Ilsandong, thành phố Goyang, tỉnh Gyeonggi (Giáo dục từ xa, 609)',
  FOOTER_LOGO: 'Klab Company',
  FOOTER_COMPANY: 'Công ty cổ phần Klab Company',
  FOOTER_DELEGATE: 'Giám đốc đại diện Lee Sang-hwan',
  FOOTER_INFORMATION_MANAGER: 'Người chịu trách nhiệm quản lý thông tin Lee Sang-hwan',
  FOOTER_ADDRESS: 'Địa chỉ: Tầng 9, số 129, đường Jayang, quận Gwangjin, thành phố Seoul',
  FOOTER_EMAIL: 'Email: enterjobedu@k-lab.me',
  FOOTER_COPYRIGHT: 'COPYRIGHT © Công ty cổ phần Klab Company ALL RIGHTS RESERVED',
  FOOTER_CHANNEL: 'Các kênh Enterjobedu',
  FOOTER_CHANNEL_LIST_COMMUNITY: 'COMMUTINY',
  FOOTER_CHANNEL_LIST_BLOG: 'BLOG',
  FOOTER_CHANNEL_LIST_YOUTUBE: 'YOUTUBE',
  FOOTER_CHANNEL_LIST_FACEBOOK: 'FACEBOOK',
  FOOTER_CHANNEL_LIST_INSTAGRAM: 'INSTAGRAM',
  FOOTER_SERVICE_CENTER: 'Trung tâm chăm sóc khách hàng',
  FOOTER_INQUIRY: 'Hỏi đáp',
  FOOTER_INQUIRY_TIME: 'Ngày thường từ 10 giờ sáng ~ 6 giờ tối (Ngoại trừ cuối tuần, ngày nghỉ lễ)',
  MAIN_ONLINE_TITLE: 'Đây là chương trình giảng dạy trực tuyến được yêu thích nhất hiện nay!',
  MAIN_OFFLINE_TITLE: 'Đừng bỏ lỡ nhé! Chương trình giảng dạy ngoại tuyến hiện đang tuyển sinh',
  MAIN_VIDEO_TITLE: 'Hãy thử làm thế này thôi! Tôi sẽ cho bạn biết chiến lược xin việc theo từng giai đoạn.',
  MAIN_EVENT_TITLE: 'Sự kiện chỉ dành cho thành viên',
  ORDER_COMPLETED_MESSAGE: 'Hãy hoàn tất việc thanh toán trước {deadline}.',
  ORDER_COMPLETED_MESSAGE_DEADLINE: 'Trong vòng 3 ngày',
  MY_PAGE_LECTURES: 'Phòng học của tôi',
  MY_PAGE_LECTURES_COLUMN_STATUS: 'Tình trạng nghe giảng',
  MY_PAGE_LECTURES_COLUMN_TITLE: 'Tên bài giảng',
  MY_PAGE_LECTURES_COLUMN_OPTION: 'lựa chọn',
  MY_PAGE_LECTURES_COLUMN_GENERATION: 'Số khóa học',
  MY_PAGE_LECTURES_COLUMN_PERIOD: 'Khoảng thời gian nghe giảng',
  MY_PAGE_LECTURES_COLUMN_DATE: 'Thời gian giảng bài',
  MY_PAGE_LECTURES_COLUMN_PLACE: 'Địa điểm giảng bài',
  MY_PAGE_LECTURES_COLUMN_SURVEY: 'sự khảo sát',
  MY_PAGE_PAYMENT: 'Lịch sử thanh toán',
  MY_PAGE_PAYMENT_MESSAGE:
    'Về cơ bản, lịch sử thanh toán được hiển thị sẽ là {lastMonth}, bạn có thể truy vấn lịch sử thanh toán trước đó bằng cách thay đổi mục ngày tháng.',
  MY_PAGE_PAYMENT_MESSAGE_LAST_MONTH: 'Trong vòng 6 tháng gần nhất',
  MY_PAGE_PAYMENT_SELECTED_COUPON: 'Hãy lựa chọn Coupon giảm giá.',
  MY_PAGE_PAYMENT_COUPON_DISABLED: 'Coupon not available for free class',
  MY_PAGE_COUPON: 'Lịch sử Coupon',
  MY_PAGE_LIKE_LECTURES: 'Danh sách mong muốn',
  MY_PAGE_EMPTY_LIKE_LECTURES: 'Không có danh sách mong muốn',
  MY_PAGE_EMPTY_LIKE_LECTURES_BTN: 'Xem danh sách bài giảng',
  MY_PAGE_PROFILE: 'Chỉnh sửa thông tin thành viên',
  MY_PAGE_INQUIRY: 'Hỏi đáp 1:1',
  MY_PAGE_HELLO_USER: 'Xin chào mừng Quý hội viên {user}!',
  MY_PAGE_AVAILABLE_COUPON: 'Có {number} Coupon có thể sử dụng được',
  MY_PAGE_PAYMENT_MODAL_CONFIRM_TITLE: 'Giấy xác nhận thanh toán',
  MY_PAGE_PAYMENT_MODAL_CONFIRM_MESSAGE:
    "Khi thanh toán, hóa đơn sẽ được tự động gửi đến địa chỉ Email mà bạn đã đăng ký thông qua 'KG INICIS'.\r\r\nBạn hãy kiểm tra cả trong hòm thư rác (Spam).\r\r\nNếu không tìm thấy, bạn hãy hỏi trong mục Hỏi đáp 1:1 để nhận được hướng dẫn chi tiết.",
  MY_PAGE_PAYMENT_MODAL_REFUND_TITLE: 'Yêu cầu hoàn tiền',
  MY_PAGE_PAYMENT_MODAL_REFUND_MESSAGE:
    'Bạn hãy đặt các câu hỏi liên quan đến yêu cầu hoàn tiền hoặc số tiền bồi hoàn tại mục Hỏi đáp 1:1 để nhận được hướng dẫn chi tiết.',
  MY_PAGE_PAYMENT_MODAL_INFO_TITLE: 'Kiểm tra thông tin thanh toán',
  PLACEHOLDER_LOGIN_EMAIL: 'Vui lòng nhập email hoặc ID của bạn.',
  PLACEHOLDER_LOGIN_PASSWORD: 'Hãy nhập mật khẩu vào.',
  PLACEHOLDER_COUPON_NUMBER: 'Hãy nhập mã Coupon (phiếu giảm giá) vào.',
  COMMON_CONFIRM: 'Kiểm tra',
  COMMON_LINK_TO_MAIN: 'Đến ngay Trang chủ',
  COMMON_LINK_TO_MAIN_2: 'Di chuyển đến Trang chủ',
  COMMON_LINK_TO_MY_LECTURES: 'Đến ngay Phòng học của tôi',
  COMMON_LINK_TO_INQUIRY: 'Đến ngay mục Hỏi đáp 1:1',
  COMMON_PAYMENT: 'Thanh toán',
  COMMON_REFERENCE: 'Truy vấn',
  COMMON_LINK_TO_SIGNUP: 'Đăng ký thành viên',
  COMMON_READ_MORE: 'Xem chi tiết',
  COMMON_COUPON_ENROLLMENT: 'Đăng ký Coupon (phiếu giảm giá)',
  COMMON_COUPON_CHECK_HISTORY: 'Kiểm tra lớp học có thể áp dụng được',
  PAYMENT_SUCCESSFULLY_COMPLETED: 'Đã thanh toán thành công',
  CHECK_MY_LECTURE_ROOM: 'Hãy kiểm tra thông tin bài giảng đã mua tại Phòng học của tôi.',
  NOT_A_MEMBER_YET: 'Bạn chưa phải là thành viên?',
  FORGOT_MEMBER_INFORMATION: 'Bạn đã quên thông tin thành viên của mình?',
  ORDER_WILL_BE_CANCELED: 'Nếu quá hạn thì đơn đặt hàng của bạn sẽ bị hủy.',
  CATEGORIES_TITLE_COMPANY: 'Theo từng doanh nghiệp',
  CATEGORIES_TITLE_FIELD: 'Theo từng ngành công nghiệp',
  CATEGORIES_TITLE_PROCESS: 'Theo từng quá trình xin việc',
  CATEGORIES_COMPANY_SM: 'SM',
  CATEGORIES_COMPANY_JYP: 'JYP',
  CATEGORIES_COMPANY_YG: 'YG',
  CATEGORIES_COMPANY_CJ: 'CJ ENM',
  CATEGORIES_COMPANY_HYBE: 'HYBE',
  CATEGORIES_COMPANY_KAKAO: 'KAKAO',
  CATEGORIES_FIELD_MUSIC: 'Âm nhạc ',
  CATEGORIES_FIELD_MOVIE: 'Phim điện ảnh',
  CATEGORIES_FIELD_BROADCAST: 'Phát thanh truyền hình',
  CATEGORIES_FIELD_PERFORMANCE: 'Biểu diễn',
  CATEGORIES_PROCESS_INTRODUCTORY: 'Nhập môn',
  CATEGORIES_PROCESS_TASK: 'Chức vụ',
  CATEGORIES_PROCESS_DOCUMENT: 'Hồ sơ',
  CATEGORIES_PROCESS_PERSONALITY: 'Tính cách và năng lực',
  CATEGORIES_PROCESS_INTERVIEW: 'Phỏng vấn',
  CATEGORIES_ETC: 'Khác',
  CATEGORY_COMPANY_1: 'SM',
  CATEGORY_COMPANY_2: 'JYP',
  CATEGORY_COMPANY_3: 'YG',
  CATEGORY_COMPANY_4: 'CJ ENM',
  CATEGORY_COMPANY_5: 'HYBE',
  CATEGORY_COMPANY_6: 'KAKAO',
  CATEGORY_COMPANY_7: 'Khác',
  CATEGORY_FIELD_1: 'Âm nhạc ',
  CATEGORY_FIELD_2: 'Phim điện ảnh',
  CATEGORY_FIELD_3: 'Phát thanh truyền hình',
  CATEGORY_FIELD_4: 'Biểu diễn',
  CATEGORY_FIELD_5: 'Khác',
  CATEGORY_PROCESS_1: 'Nhập môn',
  CATEGORY_PROCESS_2: 'Chức vụ',
  CATEGORY_PROCESS_3: 'Hồ sơ',
  CATEGORY_PROCESS_4: 'Tính cách và năng lực',
  CATEGORY_PROCESS_5: 'Phỏng vấn',
  CATEGORY_PROCESS_6: 'Khác',
  NEW: 'Theo thứ tự mới nhất',
  VIEW: 'Theo thứ tự truy vấn',
  SELLING: 'Theo thứ tự bán hàng',
  RESET: 'Khởi tạo',
  EDIT_MEMBER_INFORMATION: 'Chỉnh sửa thông tin thành viên',
  GO_YOUTUBE: 'ĐẾN KÊNH YOUTUBE',
  DISCOUNT: 'Giảm giá',
  ONLINE_CLASS: 'Lớp học trực tuyến',
  OFFLINE_CLASS: 'Lớp học ngoại tuyến',
  FOREIGNER_ONLY: 'CHỈ dành cho người nước ngoài',
  DUE: 'Đến',
  MORE: 'Xem thêm',
  ADVANCE_RESERVATION_COUNTING: 'Deadline in {n} days',
  ADVANCE_RESERVATION_COUNTING_ONE: 'Deadline in 1 day',
  ADVANCE_RESERVATION_TODAY: "It's scheduled to close",
  POSSESSION: 'Sở hữu',
  DONE_EXPIRES: 'Hoàn tất/Kết thúc',
  FIND_ID_PASSWORD: 'Tìm kiếm ID/Mật khẩu',
  NAME_OF_BANK: 'Tên ngân hàng',
  ACCOUNT_HOLDER: 'Chủ tài khoản',
  ACCOUNT_NUMBER: 'Số tài khoản',
  DEPOSIT_DEADLINE: 'Thời hạn nộp tiền',
  DEPOSIT_INFORMATION: 'Thông tin nộp tiền',
  DEPOSIT_ACCOUNT: 'Tài khoản nộp tiền',
  ORDER_NUMBER: 'Mã số đặt hàng',
  ORDER_DATE: 'Ngày đặt hàng',
  PAYMENT_DATE: 'Ngày thanh toán',
  REFUND_DATE: 'Ngày hoàn tiền',
  AMOUNT: 'Số tiền ',
  TOTAL_AMOUNT: 'Tổng số tiền',
  USE_COUPON: 'Sử dụng Coupon (Phiếu giảm giá)',
  ORDER_COMPLETED: 'Hoàn tất đặt hàng',
  APPLY_FOR_A_REFUND: 'Đăng ký hoàn tiền',
  ISSUANCE_OF_PAYMENT_CONFIRMATION: 'Cấp giấy xác nhận thanh toán',
  CONFIRM_PAYMENT_INFORMATION: 'Xác nhận thông tin thanh toán',
  TOTAL_AMOUNT_DUE: 'Tổng số tiền dự kiến thanh toán',
  CONFIRM_OF_ORDER_DETAIL_AND_AGREE: 'Xác nhận nội dung đặt hàng và đồng ý thanh toán',
  PAYMENT_COMPLETED: 'Hoàn tất thanh toán',
  REFUND_COMPLETED: 'Hoàn tất hoàn tiền',
  PAYMENT_WAITING: 'Chờ thanh toán',
  REFUND_WAITING: 'Chờ hoàn tiền',
  AMOUNT_OF_PAYMENT: 'Số tiền thanh toán',
  DISCOUNT_AMOUNT: 'Số tiền giảm giá',
  ORIGINAL_PRICE: 'Giá ấn định',
  PAYMENT_LIST: 'Mục lục thanh toán',
  PAYMENT_METHOD: 'Phương thức thanh toán',
  REFUND_POLICY: 'Chính sách hoàn tiền của Enterjobedu',
  CONSENT_TO_PROVISION_OF_PERSONAL_INFORMATION_TO_THIRD_PARTIES: 'Đồng ý cung cấp thông tin cá nhân cho bên thứ 3',
  AGREE_TO_THE_TERMS_OF_USE_FOR_PAYMENT_AGENCY_SERVICE: 'Đồng ý với Điều khoản sử dụng dịch vụ thanh toán thay',
  PURCHASE_CONFIRMATION_AGREEMENT: 'Đồng ý xác nhận mua hàng',
  SIGNUP_FORM_ALERT_SUCCESS_NICKNAME: 'Đây là biệt danh có thể sử dụng được.',
  SIGNUP_FORM_ALERT_FAIL_NICKNAME: 'Đây là biệt danh đã tồn tại.\r\r\nHãy sử dụng biệt danh khác.',
  SIGNUP_FORM_ALERT_SUCCESS_SEND: 'Mã số chứng nhận đã được gửi đi.',
  SIGNUP_FORM_ALERT_SUCCESS_CHECK_CERTIFICATION: 'Đã hoàn tất xác nhận bản thân.',
  SIGNUP_FORM_ALERT_FAIL_CHECK_CERTIFICATION: 'Không khớp với mã số chứng nhận đã được gửi. ',
  SIGNUP_FORM_CONFIRM_MODAL_TITLE: 'Xin chào mừng 🎉',
  SIGNUP_FORM_CONFIRM_MODAL_MESSAGE: 'Đã hoàn tất đăng ký thành viên Enterjobedu!',
  SIGNUP_FORM_CONFIRM_MODAL_LINK_TO_MAIN: 'Khám phá Enterjobedu',
  SIGNUP_FORM_FILE_SIZE: 'Chỉ có thể đăng ký tập tin dưới 10MB.\r\r\nDung lượng tập tin hiện tại: {size} MB',
  HEADER_USER_NAV_ADMIN: 'Quản trị viên',
  MOBILE_MAIN_VIDEO_TITLE: 'Hãy thử làm thế này thôi! Tôi sẽ cho bạn biết chiến lược xin việc theo từng giai đoạn.',
  DETAIL_TABS_CLASS_INTRODUCTION: 'Giới thiệu lớp học',
  DETAIL_TABS_CURRICULUM: 'Chương trình giảng dạy',
  DETAIL_TABS_REVIEW: 'Đánh giá',
  DETAIL_SAMPLE_VIDEO_MESSAGE: 'Hãy thử trải nghiệm miễn phí nội dung bài giảng thực tế!',
  DETAIL_CHAPTER_MESSAGE: 'Bài giảng của chúng tôi có cấu trúc như thế này!',
  DETAIL_REVIEW_MESSAGE: 'Đây là bài giảng có tổng {total} đánh giá!',
  DETAIL_INSTRUCTOR: 'Giảng viên',
  TUTOR_COMPANIES: 'Sự nghiệp',
  DETAIL_CLASS_TIME: 'Thời gian nghe giảng',
  DETAIL_CLASS_TIME_DAYS: 'Ngày {day}',
  DETAIL_NUMBER_OF_LECTURES: 'Số bài giảng',
  DETAIL_TOTAL_NUMBER_OF_LECTURES: '{total} bài',
  DETAIL_LECTURE_SATISFACTION: 'Mức độ hài lòng về bài giảng',
  DETAIL_LECTURE_SCHEDULE: 'Lịch trình giảng dạy',
  PAYMENT_IMPLEMENTS_FREE: 'Free',
  PAYMENT_IMPLEMENTS_CREDIT_OR_CHECK_CARD: 'Thẻ tín dụng (thẻ thanh toán)',
  PAYMENT_IMPLEMENTS_V_BANK: 'Tài khoản ảo',
  PAYMENT_IMPLEMENTS_KAKAOPAY: 'Kakao Pay',
  PAYMENT_IMPLEMENTS_TOSS: 'Toss',
  PAYMENT_IMPLEMENTS_PAYPAL: 'Paypal',
  PAYMENT_COUPON_NONE_OPTIONS: 'Không tồn tại Coupon có thể áp dụng. :( ',
  PAYMENT_ERROR_REQUEST: 'Yêu cầu thanh toán bị thất bại.',
  PAYMENT_ERROR: 'Đã thanh toán thất bại.',
  PAYMENT_ERROR_COUPON_1: 'Đây là Coupon không tồn tại.',
  PAYMENT_ERROR_COUPON_2: 'Đây là Coupon đã hết hạn.',
  PAYMENT_ERROR_COUPON_3: 'Không thể cấp Coupon được.\r\r\nHãy để lại câu hỏi 1:1.',
  PAYMENT_ERROR_COUPON_4: 'Đây là Coupon đã được đăng ký.',
  ORDER_COMPLETED_VBANK:
    'Tài khoản ảo đã được xử lý cấp bình thường.\r\r\n\r\r\n{vbank_name} {vbank_num}\r\r\nSố tiền nộp: {paid_amount} won\r\r\nChủ tài khoản ảo: {vbank_holder}\r\r\nTài khoản ảo này có hiệu lực đến ngày {vbank_date}.',
  ORDER_COMPLETED_PENDING: 'Trạng thái thanh toán không suôn sẻ.\r\r\nHãy thử lại.',
  MY_PAGE_LECTURES_COLUMN_REMAIN_DAYS: 'Số ngày còn lại',
  MY_PAGE_LECTURES_COLUMN_ATTENDANCE_RATE: 'Tỷ lệ nghe giảng',
  MY_PAGE_LECTURES_COLUMN_OPTION: 'lựa chọn',
  MY_PAGE_LECTURES_COLUMN_LECTURE_ROOM: 'Phòng học',
  MY_PAGE_LECTURES_SHORTCUTS: 'Chuyển đến bài giảng ngay',
  MY_PAGE_WISH_LIST: 'Như',
  MY_PAGE_PAYMENT_MESSAGE_MOBILE:
    '- Về cơ bản, lịch sử thanh toán được hiển thị sẽ là {lastMonth}.\r\r\n- Bạn có thể truy vấn lịch sử thanh toán trước đó bằng cách thay đổi mục ngày tháng.',
  MY_PAGE_POINT: 'Lịch sử điểm số',
  MY_PAGE_PROFILE_MESSAGE: 'Hãy để lại lời nhắn ở mục Hỏi đáp 1:1 khi muốn chỉnh sửa thông tin thành viên.',
  MY_PAGE_COUPON_MODAL_TITLE: 'Coupon này được áp dụng vào bài giảng bên dưới.',
  MY_PAGE_COUPON_MODAL_MOBILE_TITLE: 'Coupon này được áp dụng vào bài giảng bên dưới.',
  LECTURE_STATUS_ONGOING: 'Đang nghe giảng',
  LECTURE_STATUS_PENDING: 'Dự kiến nghe giảng',
  LECTURE_STATUS_END: 'Kết thúc nghe giảng',
  STANDARD: 'Khóa học',
  PACKAGE: '[Gói]',
  DAY: 'Ngày',
  MY_PAGE_NONE_LECTURE: 'Hãy kiểm tra ngay bây giờ bài giảng cần thiết cho bạn!',
  MY_PAGE_NONE_PAYMENT: 'Không có lịch sử thanh toán. Hãy sử dụng mục Hỏi đáp 1:1 để đặt câu hỏi. ',
  MY_PAGE_NONE_COUPON: 'Không có Coupon nào sử dụng được.',
  MY_PAGE_NOT_EXISTENCE_COUPON: 'Coupon này không tồn tại. Hãy kiểm tra mã số Coupon!',
  LANG_KO: 'Tiếng Hàn',
  LANG_EN: 'Tiếng Anh',
  LANG_JA: 'Tiếng Nhật',
  LANG_VI: 'Tiếng Việt',
  PAYMENT: 'Thanh toán',
  GO_TO_CLASSROOM: 'Đến phòng học',
  GO_TO_CLASSLIST: '강의목록 바로가기',
  CURRENCY_KOR: 'Won',
  MAKE_A_PAYMENT: 'Thanh toán',
  MAKE_A_WAITING: 'Tham gia danh sách chờ 👆🏻',
  MAKE_A_WAITING_TEXT:
    'Bạn có muốn đăng ký làm người đăng ký danh sách chờ không? \n\n* Chúng tôi sẽ thông báo qua email hoặc tin nhắn thông báo khi bài giảng được mở.',
  WAITING_SUCCESS: 'Đăng ký chờ hoàn tất.',
  WAITING_FAIL_ALREADY: 'Bạn đã có tên trong danh sách chờ rồi. \n\n* Tôi sẽ liên lạc với bạn sớm nhất có thể.',
  WAITING_ALREADY_PURCHASED: 'Khóa học này đã được thanh toán (hoặc đang được thanh toán).',
  ABBREVIATION_MONDAY: 'Thứ hai',
  ABBREVIATION_TUESDAY: 'Thứ ba',
  ABBREVIATION_WEDNESDAY: 'Thứ tư',
  ABBREVIATION_THURSDAY: 'Thứ năm',
  ABBREVIATION_FRIDAY: 'Thứ sáu',
  ABBREVIATION_SATURDAY: 'Thứ bảy',
  ABBREVIATION_SUNDAY: 'Chủ nhật',
  MEET_YOUR_CRITERIA: 'Xem bài giảng phù hợp với điều kiện',
  PROFILE_IMAGE_ALT: 'Ảnh đại diện',
  FOLD: 'Gấp lại',
  PAYMENT_FAIL: 'Thanh toán thất bại',
  CLASSROOM_ATTACHED_FILE: 'Tài liệu bài giảng',
  CLASSROOM_DOWNLOAD: 'Tải về',
  CLASSROOM_CHAPTER: 'Mục lục bài giảng',
  CLASSROOM_COMPLETE_RATE: 'Tỷ lệ nghe giảng',
  CLASSROOM_ATTENDED_TIME: 'Thời gian nghe giảng',
  CLASSROOM_CLASS_TIME: 'Thời gian giảng dạy',
  LOGIN_ALERT_UNAUTH: 'Hãy kiểm tra Email hoặc mật khẩu.',
  LOGIN_FACEBOOK_ERROR: 'Bạn đã đăng nhập Facebook thất bại.',
  LOGIN_VALIDATE_ERROR: 'Hãy kiểm tra mật khẩu hoặc Email.',
  SOCIAL_LOGIN_ERROR: 'Đã xảy ra lỗi không rõ nguyên nhân trong quá trình đăng nhập vào mạng xã hội.',
  SIGNUP_FROM_INTRO: 'Hãy đồng hành cùng Enterjobedu vì hiện tại và tương lai của ngành giải trí.',
  SIGNUP_FORM_USERNAME: 'Họ tên (cần thiết)',
  SIGNUP_FORM_USERNAME_PLACEHOLDER: 'Hãy viết tên của bản thân bạn vào (tiếng Hàn/tiếng Anh).',
  SIGNUP_FORM_AUTHENTICATE_ERROR: 'Hãy kiểm tra mã xác minh gồm 6 chữ số.',
  SIGNUP_FROM_ALERT_FILE_UPLOAD_ERROR_MESSAGE: 'Chỉ có thể đăng ký tập tin dưới 10MB.',
  SIGNUP_FORM_SUCCESS_NICKNAME: 'Đây là biệt danh bạn có thể sử dụng.',
  SIGNUP_FORM_NICKNAME_VALIDATION_ERROR_MESSAGE:
    'Hãy sử dụng tiếng Hàn, tiếng Anh để điền vào trong khoảng tối đa 10 ký tự. ',
  SIGNUP_FORM_FAIL_NICKNAME: 'Đây là biệt danh đã được sử dụng.',
  SIGNUP_FORM_FAIL_EMAIL: 'Hãy điền vào sao cho phù hợp với dạng thức Email.',
  ID_SIGNUP_ERROR_1: 'Đây là số điện thoại đã được dùng để đăng ký tham gia.',
  ID_SIGNUP_ERROR_2: 'Hãy thử gửi lại mã xác minh.',
  HEADER_LOGGED_IN_USER_MESSAGE: '{user} hãy đồng hành cùng chúng tôi để hướng đến mục tiêu! ',
  DETAIL_LECTURE_NOT_LOGGED_IN_USER: 'Để thanh toán được, trước tiên bạn hãy đăng nhập.',
  GENERAL_NOT_LOGGED_IN_USER: 'Bạn cần đăng nhập để tiến hành.',
  DETAIL_LECTURE_NOT_SELECT_SCHEDULE: 'Hãy lựa chọn lịch giảng dạy.',
  DETAIL_LECTURE_NOT_SELLING:
    'Đây là sản phẩm không thể bán. Nếu bạn để lại lời nhắn ở mục Hỏi đáp 1:1, chúng tôi sẽ trả lời bạn. ',
  PAYMENT_ERROR_2: 'Thanh toán thất bại. Nội dung lỗi:',
  PAYMENT_ALREADY_BEEN_PAID: 'Đây là bài giảng đã được thanh toán.',
  PAYMENT_NOT_PAID_MESSAGE:
    'Không thể thanh toán 0 won. Nếu bạn để lại lời nhắn ở mục Hỏi đáp 1:1, chúng tôi sẽ hướng dẫn bạn. ',
  ID_COUPON_ERROR_MEESGAE_1: 'Đây là Coupon không tồn tại.',
  ID_COUPON_ERROR_MEESGAE_2: 'Đây là Coupon đã hết hạn.',
  ID_COUPON_ERROR_MEESGAE_3: 'Không thể cấp Coupon. Hãy để lại lời nhắn ở mục Hỏi đáp 1:1.',
  ID_COUPON_ERROR_MEESGAE_4: 'Đây là Coupon đã được đăng ký.',
  MY_PAGE_PROFILE_MESSAGE_1:
    'Đội ngũ vận hành Enterjobedu đang trực tiếp thực hiên việc chỉnh sửa các thông tin quan trọng của Quý hội viên!',
  MY_PAGE_PROFILE_MESSAGE_2:
    'Nếu bạn để lại lời nhắn trên mục Hỏi đáp 1:1, chúng tôi sẽ trả lời bạn nhanh nhất có thể.',
  MY_PAGE_PROFILE_INQUIRY: 'Để lại lời nhắn ở mục Hỏi đáp 1:1',
  MY_PAGE_PROFILE_SIGNOUT: 'Từ bỏ tư cách thành viên',
  MY_PAGE_SIGNOUT_MESSAGE: '"Bạn có thực sự muốn từ bỏ tư cách thành viên không? Chúng tôi thực sự rất tiếc."',
  MY_PAGE_SIGNOUT_TERM_1:
    'Nếu từ bỏ tư cách thành viên bây giờ thì bạn sẽ không thể tiếp tục nghe các bài giảng hiện hành cũng như các bài giảng dự kiến sẽ được tiến hành.',
  MY_PAGE_SIGNOUT_TERM_2:
    'Nếu từ bỏ tư cách thành viên bây giờ thì tất cả các Coupon mà bạn sở hữu sẽ biến mất. Dù bạn tái đăng ký thành viên thì chúng tôi cũng sẽ không thể cấp lại cho bạn được.',
  MY_PAGE_SIGNOUT_TERM_3: 'Các hoạt động như những bài đánh giá mà bạn đã viết trước kia sẽ không bị xóa đi.',
  MY_PAGE_SIGNOUT_TERM_AGREE:
    'Tôi đã xác nhận các nội dung cần lưu ý khi từ bỏ tư cách hội viên ở bên trên, và tôi đồng ý với những điều này.',
  MY_PAGE_SIGNOUT_FORM: 'Lý do khiến tôi muốn từ bỏ tư cách hội viên của Enterjobedu là…',
  MY_PAGE_SIGNOUT_CONFIRM: 'Hoàn tất việc từ bỏ tư cách thành viên',
  MY_PAGE_SIGNOUT_POLICY: 'Tài khoản đã xoá không thể đăng ký lại trong vòng 30 ngày!',
  MY_PAGE_NONE_EXPIRED_COUPON: 'Không có lịch sử Coupon đã hoàn thành/hết hạn.',
  MY_PAGE_PAYMENT_MODAL_CONFIRM_BOLD1: 'Hãy kiểm tra cả hòm thư rác.',
  MY_PAGE_PAYMENT_MODAL_CONFIRM_BOLD2: 'Thông qua mục Hỏi đáp 1:1',
  MY_PAGE_PAYMENT_MODAL_REFUND_BOLD: 'Thông qua mục Hỏi đáp 1:1',
  COMMON_CANCEL: 'Hủy',
  DEVELOP_MESSAGE: 'Đang chuẩn bị dịch vụ Enterjobedu.',
  USED: 'Đã sử dụng xong',
  EXPIRED: 'Đã hết thời hạn sử dụng',
  GO_TO_SERVICE_CENTER: 'Đến ngay Trung tâm chăm sóc khách hàng',
  ERROR_GENERAL: 'Phát sinh lỗi không rõ nguyên nhân',
  ERROR_NETWORK: 'Hãy kiểm tra trạng thái kết nối mạng.',
  ID_SIGNUP_ERROR_3: 'Đây là Email đã đăng ký hội viên.',
  ID_SIGNUP_ERROR_4: 'Đã gửi Email trả lời thất bại.',
  ID_SIGNUP_ERROR_5: 'Đây là người dùng đã đăng ký hội viên. ',
  ID_SIGNUP_ERROR_6: 'Có thể đăng ký lại sau khi từ bỏ tư cách hội viên từ 30 ngày trở lên. ',
  SIGNUP_FORM_COUNTRY: 'Thông tin quốc gia (cần thiết)',
  MY_PAGE_LECTURES_SINGLE: 'Bài giảng đơn lẻ',
  MY_PAGE_LECTURES_PACKAGE: 'Bài giảng theo gói',
  마감임박: 'Số lượng có hạn',
  '이미 마감된 강의입니다': 'Lớp học này đã hết thời hạn có thể truy cập.',

  'Album Credit DB': 'Album Credit DB',
  '국내에서 발매된 {totalCount}개 앨범 크레딧 정보 집합소!{br}KPOP을 세계로 알리고 있는 Staff 정보를 확인해보세요.':
    '"Tổng hợp credit từ {totalCount} album đã ra mắt!{br}Hãy thử xem xem những staff nào đã góp phần đưa KPOP đến với thế giới nhé!"',
  RELEASE_DATE_DESC: 'Thứ tự phát hành gần nhất',
  RELEASE_DATE_ASC: 'Thứ tự phát hành trước kia',
  '전체 {totalCount}개': 'Tổng {totalCount} album được đăng ký dữ liệu',
  기획사: 'Công ty chủ quản',
  아티스트: 'Nghệ sĩ',
  '{totalCount}개의 앨범 크레딧 보기': 'Xem phần credit của {totalCount} album',
  발매일: 'Ngày phát hành',
  '아티스트와 관련된 다른 앨범 정보도 궁금하지 않으세요?':
    'Bên cạnh các nghệ sĩ, ai là những người góp phần tạo nên một album?',
  '앨범 크레딧 "이분들의 땀과 노력이 담겼어요!"':
    'Album credit {br}"Những người này đã bỏ tâm huyết vào album này đây!"',
  '다른 앨범 정보도 궁금하지 않으세요?': 'Các bạn có tò mò những thông tin khác về các album KPOP không?',
  MY_PAGE_LECTURES_COLUMN_REVIEW: 'Review',
  MY_PAGE_LECTURES_WRITE_REVIEW_POPUP_TITLE: 'Course Review',
  MY_PAGE_LECTURES_WRITE_REVIEW_POPUP_BANNER:
    'When writing a course review, we will give you a 1,000 won discount coupon within 1 business day!',
  MY_PAGE_LECTURES_WRITE_REVIEW_COMPLETE: 'Completed',
  MY_PAGE_LECTURES_WRITE_REVIEW: 'Write',
  MY_PAGE_LECTURES_WRITE_REVIEW_GENERAL_TITLE: 'Total Review',
  MY_PAGE_LECTURES_WRITE_REVIEW_GENERAL_PLACEHOLDER:
    'Write your overall opinion on the lecture! (at least 100bytes )\r\nYour review will be a huge asset to those who are considering purchasing our courses and  membership.  ',
  MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_HEADER:
    'The question below is to improve EnterJob Edu content. {br}Only Enter Job Edu staff members will be able check this  answer, so please feel free to answer!',
  MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_CONTENT_QUESTION:
    '<strong>Class Satisfaction</strong> : Were you satisfied with the composition of content, accuracy of information, and overall topic suitablity?',
  MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_TIME_QUESTION:
    "<strong>Class Time Satisfaction</strong> : Were you satisfied with the opening time of the class, the instructor's compliance with the class time, and the overall running time of the lecture?",
  MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_TUTOR_QUESTION:
    "<strong>Instructor satisfaction</strong> : Please evaluate the instructor's knowledge, experience, and delivery skills!",
  MY_PAGE_LECTURES_WRITE_REVIEW_DETAIL_PLACEHOLDER:
    'If there was anything that you were specifically dissatisfied with, we will try our best to improve. ( at least 100bytes )',
  MY_PAGE_LECTURES_WRITE_REVIEW_SUGGESTION_QUESTION:
    'Is there any other type content or needed functions that Enter Job Edu should consider?',
  MY_PAGE_LECTURES_WRITE_REVIEW_SUGGESTION_PLACEHOLDER:
    'Please write down the type of information you struggled to find while preparing for employment at an entertainment company or what you want to hear from the incumbent!\r\nHere at Enter Job Edu, we will always try our best to solve your problems.',
  MY_PAGE_LECTURES_WRITE_REVIEW_SUBMIT_BTN_TEXT: 'Review Completed',
  MY_PAGE_LECTURES_WRITE_REVIEW_FORM_ERROR: 'Please fill in all the categories that are mandatory.',
  REVIEW_WARNING_RATE: 'You must take 30% or higher to write.',
  REVIEW_WARNING_ENDDATE: 'You can write after the end of the course.',
  COMMON_CLOSE: 'gần',
  COMMON_ALWAYS_CLOSE: 'Không mở lại',
  COMMON_1DAY_CLOSE: 'không mở hôm nay',
  SEARCH_INPUT_PLACEHOLDER: 'Tìm kiếm công việc, công ty, v.v. mà bạn quan tâm.',
  SEARCH_RECENT_KEYWORDS_TITLE: 'các tìm kiếm gần đây',
  COMMON_DELETE_ALL: 'Xóa tất cả',
  SEARCH_RECENT_KEYWORD_EMPTY: 'Không có tìm kiếm nào gần đây.',
  SEARCH_RESULT_HEADER: 'Kết quả tìm kiếm cho {keyword}',
  SEARCH_RESULT_EMPTY_KEYWORD: 'Không tìm thấy kết quả nào cho {keyword}.',
  SEARCH_RESULT_EMPTY_DESC:
    'Kiểm tra khoảng cách. <br /> Kiểm tra xem chính tả của từ có chính xác không. <br /> Kiểm tra xem bạn đã nhập tiếng Hàn bằng tiếng Anh hay tiếng Anh bằng tiếng Hàn. <br /> Hãy thử sử dụng một cụm từ tìm kiếm khác.',
  SEARCH_RECOMMEND_KEYWORDS_TITLE: 'cụm từ tìm kiếm được đề xuất',
  SEARCH_RECOMMEND_CONTENTS_LECTURES: 'Đừng bỏ lỡ các bài giảng phổ biến ngay bây giờ!',
  SEARCH_RECOMMEND_CONTENTS_ALBUMS: 'gợi ý! Kiểm tra các khoản tín dụng cho album được phát hành gần đây. ',
  MY_PAGE_LECTURES_COLUMN_ATTACHED_FILE: 'bài giảng tài liệu',
  MY_PAGE_LECTURES_DOWNLOAD_FILE: 'Tải xuống',
  MY_PAGE_PROFILE_UPDATE_SUCCESS: 'Thông tin đã được sửa chữa.',
  NO_LECTURES: 'Nội dung đang được chuẩn bị.',
  LIST: 'Danh sách',
  NOTICE_DETAIL_ATTACH: 'tập tin đính kèm',
  LECTURE_SELLING_COUNT: '{count} sinh viên',
  REQUEST_FOR_CLASS: '신청하기 👆🏻',
};
export default vi;
